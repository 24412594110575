import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import {
  addStockToAProduct,
  closeStockForProduct,
  getStockHistoryByProductAndStoragePlace,
  getStockHistoryByProductAndWarehouse,
  getStocksQuantityForProduct,
  getUseStockForProductAndStoragePlace,
} from "../services/StockService";
import { AxiosError, AxiosResponse } from "axios";
import {
  ICloseStockParams,
  IStockHistory,
  IUseStockParams,
} from "types/stock.type";
import { IProduct } from "types/product.type";
import { IWarehouse } from "types/warehouse.type";
import { IStoragePlace } from "src/types/storage-place.type";
import { IUsePlace } from "src/types/use-place.type";

type TUseAddStockToAProductConfig = UseMutationOptions<
  AxiosResponse<void>,
  AxiosError,
  {
    productId: IProduct["id"];
    storagePlaceId: IStoragePlace["id"];
    actionDate: IStockHistory["actionDate"];
    actionQuantity: IStockHistory["actionQuantity"];
  }
>;

export const useAddStockToAProduct = (config?: TUseAddStockToAProductConfig) =>
  useMutation({
    mutationFn: ({ productId, storagePlaceId, actionDate, actionQuantity }) =>
      addStockToAProduct(productId, storagePlaceId, actionDate, actionQuantity),
    ...config,
  });

interface IUseStocksQuantityForProductProps {
  productId: IProduct["id"];
}

export const useStocksQuantityForProduct = (
  props: IUseStocksQuantityForProductProps,
) => {
  const { productId } = props;

  const { data, ...rest } = useQuery({
    queryKey: ["stocks-quantity-product", { productId }],
    queryFn: async () => getStocksQuantityForProduct(productId),
  });

  return {
    stocksQuantityForProduct: data?.data || [],
    ...rest,
  };
};

interface IUseStockHistoryByProductAndStoragePlaceProps {
  productId: IProduct["id"];
  storagePlaceId: IStoragePlace["id"];
  config: Partial<
    UseQueryOptions<
      AxiosResponse<{
        data: IStockHistory[];
      }>,
      AxiosError
    >
  >;
}

export const useStockHistoryByProductAndStoragePlace = (
  props: IUseStockHistoryByProductAndStoragePlaceProps,
) => {
  const { productId, storagePlaceId, config } = props;

  const { data, ...rest } = useQuery({
    queryKey: [
      "stock-history-by-product-and-storage-place",
      { productId, storagePlaceId },
    ],
    queryFn: async () =>
      getStockHistoryByProductAndStoragePlace(productId, storagePlaceId),
    ...config,
  });

  return {
    stockHistoryByProductAndStoragePlace: data?.data?.data || [],
    ...rest,
  };
};

interface IUseStockHistoryByProductAndWarehouseProps {
  productId: IProduct["id"];
  warehouseId: IWarehouse["id"];
  config: Partial<
    UseQueryOptions<
      AxiosResponse<{
        data: IStockHistory[];
      }>,
      AxiosError
    >
  >;
}

export const useStockHistoryByProductAndWarehouse = (
  props: IUseStockHistoryByProductAndWarehouseProps,
) => {
  const { productId, warehouseId, config } = props;

  const { data, ...rest } = useQuery({
    queryKey: [
      "stock-history-by-product-and-warehouse",
      { productId, warehouseId },
    ],
    queryFn: async () =>
      getStockHistoryByProductAndWarehouse(productId, warehouseId),
    ...config,
  });

  return {
    stockHistoryByProductAndWarehouse: data?.data?.data || [],
    ...rest,
  };
};

type TUseStockForProductAndStoragePlaceConfig = UseMutationOptions<
  AxiosResponse<void>,
  AxiosError,
  {
    productId: IProduct["id"];
    storagePlaceId: IStoragePlace["id"];
    params: IUseStockParams;
  }
>;

export const useUseStockForProductAndStoragePlace = (
  config?: TUseStockForProductAndStoragePlaceConfig,
) =>
  useMutation({
    mutationFn: ({ productId, storagePlaceId, params }) =>
      getUseStockForProductAndStoragePlace(productId, storagePlaceId, params),
    ...config,
  });

type TUseCloseStockForProductConfig = UseMutationOptions<
  AxiosResponse<void>,
  AxiosError,
  {
    productId: IProduct["id"];
    usePlaceId: IUsePlace["id"];
    params: ICloseStockParams;
  }
>;

export const useCloseStockForProduct = (
  config: TUseCloseStockForProductConfig,
) =>
  useMutation({
    mutationFn: ({ productId, usePlaceId, params }) =>
      closeStockForProduct(productId, usePlaceId, params),
    ...config,
  });
