import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import LinkUi from "@mui/material/Link";
import Divider from "@mui/material/Divider";
// @ts-ignore
import Pluralize from "react-pluralize";
import PageLoader from "../Loaders/PageLoader";
import WarehouseImage from "../Warehouses/WarehouseImage";
import UsePlaceImage from "../UsePlaces/UsePlaceImage";
import { Theme } from "@mui/material";
import { IProduct } from "types/product.type";
import { IWarehouse } from "types/warehouse.type";
import { IUsePlace } from "src/types/use-place.type";

const useStyles = makeStyles()((theme: Theme) => ({
  paper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2, 3, 3),
  },
  noLinkeds: {
    margin: "auto",
    textAlign: "center",
  },
  displayUsePlacePaperContainer: {
    minHeight: "100%",
    borderRadius: 0,
    border: `1px solid ${theme.palette.primary.dark}`,
  },
  warehouseImageWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 150,
    height: 150,
    maxWidth: 150,
    maxHeight: 150,
    margin: "auto",
  },
  warehouseImage: {
    maxHeight: "100%",
    maxWidth: "100%",
  },
  warehouseTitle: {
    fontSize: "18px",
    textAlign: "center",
    color: theme.palette.text.secondary,
    wordBreak: "break-all",
    margin: "auto",
  },
  warehouseTitleContainer: {
    display: "flex",
  },
  usePlacesOnWarehouseContainer: {
    wordBreak: "break-word",
    textAlign: "right",
    margin: "auto",
    width: "auto",
  },
  usePlaceImageWrapper: {
    height: "250px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  usePlaceImage: {
    maxHeight: "100%",
    maxWidth: "100%",
  },
  usePlaceTitle: {
    fontSize: "18px",
    textAlign: "center",
    color: theme.palette.text.secondary,
    wordBreak: "break-all",
  },
  linkGoToWarehouseOrUsePlaceDetail: {
    "&:hover": {
      background: theme.palette.primary.dark,
    },
  },
  displayUsePlaceContainer: {
    margin: theme.spacing(2, 0),
  },
  paperUsePlace: {
    border: "1px solid",
    borderColor: theme.palette.primary.dark,
  },
}));

const Title = () => {
  return (
    <Typography component="h1" variant="h5">
      Lieux d'utilisation
    </Typography>
  );
};

interface IProductUsePlacesProps {
  productId: IProduct["id"];
  productHasOpeningDate: boolean;
  loading?: boolean;
  warehouses: Array<IWarehouse>;
  usePlaces: Array<IUsePlace>;
  callbackAddUseStock: () => void;
}

const ProductUsePlaces = (props: IProductUsePlacesProps) => {
  const { loading = false, warehouses, usePlaces = [] } = props;

  type TDisplayUsePlaces = Array<{
    id: IWarehouse["id"];
    name: IWarehouse["name"];
    usePlaces: Array<IUsePlace>;
    warehouse?: Array<IWarehouse>;
  }>;

  const { classes } = useStyles();

  const [displayUsePlaces, setDisplayUsePlaces] = useState<TDisplayUsePlaces>(
    [],
  );

  useEffect(() => {
    if (!loading) {
      const newWarehousesToProduct: Array<IWarehouse> = warehouses.filter(
        (warehouse) =>
          usePlaces.find((linked) => linked.warehouseId === warehouse.id),
      );

      const newDisplayUsePlaces: TDisplayUsePlaces = [];
      newWarehousesToProduct.forEach((newWarehouseToProduct) => {
        const usePlacesOnWarehouse = usePlaces.filter(
          (usePlace) => usePlace.warehouseId === newWarehouseToProduct.id,
        );
        newDisplayUsePlaces.push({
          ...newWarehouseToProduct,
          usePlaces: usePlacesOnWarehouse,
        });
      });

      setDisplayUsePlaces(newDisplayUsePlaces);
    }
  }, [loading, warehouses, usePlaces]);

  if (!loading && usePlaces.length === 0) {
    return (
      <Paper className={classes.paper}>
        <Title />
        <div className={classes.noLinkeds}>
          <div>Aucun lieu d'utilisation lié au produit.</div>
        </div>
      </Paper>
    );
  }

  return (
    <Paper className={classes.paper}>
      {loading ? (
        <PageLoader />
      ) : (
        <>
          <Title />
          {displayUsePlaces?.map((warehouse: IWarehouse) => [
            <Grid
              key={warehouse.id}
              container
              className={classes.displayUsePlaceContainer}
            >
              <Grid item xs={12}>
                <Paper
                  className={classes.displayUsePlacePaperContainer}
                  elevation={0}
                >
                  <LinkUi
                    to={`/warehouses/${warehouse.id}`}
                    component={Link}
                    underline="none"
                  >
                    <div className={classes.linkGoToWarehouseOrUsePlaceDetail}>
                      <Grid container>
                        <Grid item className={classes.warehouseImageWrapper}>
                          <WarehouseImage
                            loaderSize={80}
                            maxWidth={150}
                            maxHeight={150}
                            rootStyle={classes.warehouseImage}
                            warehouseId={warehouse.id}
                          />
                        </Grid>
                        <Grid
                          item
                          xs
                          className={classes.warehouseTitleContainer}
                        >
                          <Typography
                            className={classes.warehouseTitle}
                            variant="h4"
                          >
                            {warehouse.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </LinkUi>
                  <Divider />
                  <Grid
                    className={classes.usePlacesOnWarehouseContainer}
                    container
                    spacing={5}
                  >
                    {warehouse.usePlaces &&
                      warehouse.usePlaces.map((usePlaceElt: IUsePlace) => (
                        <Grid key={usePlaceElt.id} item lg={4} md={6} xs={12}>
                          <Paper
                            elevation={0}
                            classes={{ root: classes.paperUsePlace }}
                          >
                            <LinkUi
                              to={`/use-places/${usePlaceElt.id}`}
                              component={Link}
                              underline="none"
                            >
                              <div
                                className={
                                  classes.linkGoToWarehouseOrUsePlaceDetail
                                }
                              >
                                <div className={classes.usePlaceImageWrapper}>
                                  <UsePlaceImage
                                    rootStyle={classes.usePlaceImage}
                                    usePlaceId={usePlaceElt.id}
                                  />
                                </div>
                                <Typography
                                  className={classes.usePlaceTitle}
                                  variant="h4"
                                >
                                  {usePlaceElt.name}
                                </Typography>
                              </div>
                            </LinkUi>
                            <Divider />
                          </Paper>
                        </Grid>
                      ))}
                  </Grid>
                </Paper>
              </Grid>
            </Grid>,
          ])}
        </>
      )}
    </Paper>
  );
};

export default ProductUsePlaces;
