import { useState } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/lab/Autocomplete";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { makeStyles } from "tss-react/mui";
import { useQueryClient } from "@tanstack/react-query";
import PageLoader from "../Loaders/PageLoader";
import SnackbarCloseIcon from "../Snackbar/SnackbarCloseIcon";
import UsePlaceImage from "./UsePlaceImage";
import {
  useCreateUsePlace,
  useEditUsePlace,
} from "../../Hooks/use-places.hook";
import { IWarehouse } from "types/warehouse.type";
import { IProduct } from "types/product.type";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { IUsePlace } from "src/types/use-place.type";
import UsePlaceLinkedToProductsModal from "./UsePlaceLinkedToProductsModal";

const useStyles = makeStyles()((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2, 3, 3),
  },
  submit: {
    float: "right",
    marginTop: theme.spacing(8),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  imageWrapper: {
    display: "flex",
    height: "250px",
  },
  image: {
    margin: "auto",
    maxHeight: "100%",
    maxWidth: "100%",
  },
  popupIndicator: {
    display: "none",
  },
  noWarehouse: {
    margin: "auto",
    textAlign: "center",
  },
}));

interface ICreateOrUpdateUsePlaceProps {
  loading?: boolean;
  usePlace?: IUsePlace | null;
  callback?: () => void;
  title: string;
  warehouses: Array<IWarehouse>;
}

const CreateOrUpdateUsePlace = (props: ICreateOrUpdateUsePlaceProps) => {
  const {
    loading = false,
    usePlace = null,
    callback = null,
    title,
    warehouses,
  } = props;

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { classes } = useStyles();

  const [productsLinkeds, setProductsLinkeds] = useState<Array<IProduct>>([]);

  const { mutate: createUsePlace, isPending: isPendingCreateUsePlace } =
    useCreateUsePlace({
      onSuccess: ({ data }) => {
        enqueueSnackbar(`Le lieu d'utilisation "${data.name}" a été ajouté.`, {
          variant: "success",
          action: (snackbarKey) => (
            <SnackbarCloseIcon snackbarKey={snackbarKey} />
          ),
        });
        navigate(`/use-places/${data.id}`);
      },
      onError: ({ config }) => {
        const { data } = config as AxiosRequestConfig;

        enqueueSnackbar(
          `Une erreur est survenue lors de l'ajout du lieu d'utilisation "${data.get("name")}".`,
          {
            variant: "error",
            action: (snackbarKey) => (
              <SnackbarCloseIcon snackbarKey={snackbarKey} />
            ),
          },
        );
      },
    });

  const { mutate: editUsePlace, isPending: isPendingEditUsePlace } =
    useEditUsePlace({
      onSuccess: ({ data }) => {
        enqueueSnackbar(
          `Le lieu d'utilisation "${usePlace?.name || ""}" a été mis à jour.`,
          {
            variant: "success",
            action: (snackbarKey) => (
              <SnackbarCloseIcon snackbarKey={snackbarKey} />
            ),
          },
        );

        if (usePlace?.pictureFileName !== data.pictureFileName) {
          queryClient.invalidateQueries({
            queryKey: ["use-place-picture", { usePlaceId: usePlace?.id }],
            exact: true,
          });
        }

        queryClient.refetchQueries({
          queryKey: ["use-place", { usePlaceId: usePlace?.id }],
          exact: true,
        });

        if (callback) {
          callback();
        }
      },
      onError: ({ response }) => {
        const { data } = response as AxiosResponse;

        const usePlaceHaveProductsLinkeds =
          data &&
          data.title &&
          data.title.error &&
          data.title.message &&
          data.title.productsLinkeds &&
          data.title.error === "USE_PLACE_HAVE_PRODUCTS_LINKEDS";

        if (usePlaceHaveProductsLinkeds) {
          setProductsLinkeds(data?.title?.productsLinkeds);
        } else {
          enqueueSnackbar(
            `Une erreur est survenue lors de la mise à jour du lieu d'utilisation "${usePlace?.name || ""}".`,
            {
              variant: "error",
              action: (snackbarKey) => (
                <SnackbarCloseIcon snackbarKey={snackbarKey} />
              ),
            },
          );
        }
      },
    });

  interface IInitialValues {
    usePlaceName: string;
    warehouseId: string;
    usePlacePicture?: string;
  }

  const initialValues: IInitialValues = {
    usePlaceName: usePlace?.name || "",
    usePlacePicture: undefined,
    warehouseId: usePlace?.warehouseId?.toString() || "",
  };

  if (!loading && (!warehouses || warehouses.length === 0)) {
    return (
      <Paper className={classes.paper}>
        <div className={classes.noWarehouse}>
          <div>Impossible de récupérer les entrepôts.</div>
          <div>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate("/use-places")}
            >
              Retourner au listing des lieux d'utilisation.
            </Button>
          </div>
        </div>
      </Paper>
    );
  }

  return (
    <Paper className={classes.paper}>
      {loading ? (
        <PageLoader />
      ) : (
        <>
          {productsLinkeds.length > 0 && (
            <UsePlaceLinkedToProductsModal
              title={`Il n'est pas possible de changer d'entrepôt le lieu d'utilisation "${usePlace?.name || ""}".`}
              productsLinkeds={productsLinkeds}
              usePlace={usePlace || undefined}
              handleClose={() => setProductsLinkeds([])}
            />
          )}
          <Typography component="h1" variant="h5">
            {title}
          </Typography>
          {usePlace && (
            <div className={classes.imageWrapper}>
              <UsePlaceImage
                rootStyle={classes.image}
                usePlaceId={usePlace.id}
              />
            </div>
          )}
          <Formik
            initialValues={initialValues}
            onSubmit={({
              usePlaceName,
              warehouseId,
              usePlacePicture,
            }: IInitialValues) => {
              const formData = new FormData();
              formData.append("name", usePlaceName);
              formData.append("warehouseId", warehouseId);
              if (usePlacePicture) {
                formData.append("picture", usePlacePicture);
              }

              if (!usePlace) {
                createUsePlace({ usePlace: formData });
              } else {
                editUsePlace({
                  usePlaceId: usePlace.id,
                  usePlace: formData,
                });
              }
            }}
            validationSchema={yup.object().shape({
              usePlaceName: yup
                .string()
                .required(`Le nom du lieu d'utilisation est requis.`),
              usePlacePicture: yup
                .mixed<File>()
                .test(
                  "fileFormat",
                  "Le format de l'image n'est pas valide.",
                  (value) => value?.type?.startsWith("image/") || true,
                ),
              warehouseId: yup
                .string()
                .required(
                  `L'entrepôt dans lequel se trouve le lieu d'utilisation est requis.`,
                )
                .test(
                  "warehouseId",
                  "L'entrepôt dans lequel se trouve le lieu d'utilisation n'est pas valide.",
                  (value) =>
                    !!warehouses.find((warehouse) => warehouse?.id === value),
                ),
            })}
            render={({
              values,
              touched,
              errors,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit} noValidate>
                <TextField
                  label="Nom du lieu d'utilisation"
                  type="text"
                  name="usePlaceName"
                  autoFocus
                  value={values.usePlaceName}
                  onChange={handleChange}
                  fullWidth
                  className={classes.textField}
                  helperText={
                    errors.usePlaceName &&
                    touched.usePlaceName &&
                    errors.usePlaceName
                  }
                  error={!!errors?.usePlaceName && touched?.usePlaceName}
                />

                <Autocomplete
                  onChange={(_, value) => {
                    setFieldValue("warehouseId", value ? value.id : "");
                  }}
                  value={
                    warehouses[
                      warehouses.findIndex(
                        (warehouse) =>
                          warehouse?.id.toString() === values.warehouseId,
                      )
                    ]
                  }
                  options={warehouses}
                  getOptionLabel={(option) => option.name}
                  disableClearable
                  classes={{ popupIndicator: classes.popupIndicator }}
                  noOptionsText="Aucun entrepôt"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Entrepôt"
                      fullWidth
                      className={classes.textField}
                      helperText={
                        errors.warehouseId &&
                        touched.warehouseId &&
                        errors.warehouseId
                      }
                      error={!!errors?.warehouseId && touched?.warehouseId}
                    />
                  )}
                />

                <TextField
                  label={usePlace ? "Mettre à jour l'image" : "Image"}
                  inputProps={{ accept: "image/*" }}
                  InputLabelProps={{ shrink: true }}
                  type="file"
                  name="usePlacePicture"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const { files } = event.currentTarget;

                    setFieldValue(
                      "usePlacePicture",
                      files && files[0] ? files[0] : undefined,
                    );
                  }}
                  fullWidth
                  className={classes.textField}
                  helperText={
                    errors.usePlacePicture &&
                    touched.usePlacePicture &&
                    errors.usePlacePicture
                  }
                  error={!!errors?.usePlacePicture && !!touched.usePlacePicture}
                />

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={isPendingEditUsePlace || isPendingCreateUsePlace}
                >
                  {(isPendingEditUsePlace || isPendingCreateUsePlace) && (
                    <CircularProgress color="inherit" size={14} />
                  )}
                  {(!isPendingEditUsePlace || !isPendingCreateUsePlace) &&
                    (usePlace ? "Mettre à jour" : "Ajouter")}
                </Button>
              </form>
            )}
          />
        </>
      )}
    </Paper>
  );
};

export default CreateOrUpdateUsePlace;
