import { Link } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import LinkUi from "@mui/material/Link";
import Divider from "@mui/material/Divider";
import PageLoader from "../Loaders/PageLoader";
import ProductImage from "../Products/ProductImage";
import { Theme } from "@mui/material";
import { IProduct } from "types/product.type";

const useStyles = makeStyles()((theme: Theme) => ({
  paper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2, 3, 3),
  },
  noLinkeds: {
    margin: "auto",
    textAlign: "center",
  },
  productsToUsePlaceContainer: {
    wordBreak: "break-word",
    padding: theme.spacing(1, 1),
    textAlign: "right",
  },
  productImageWrapper: {
    height: "250px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  productImage: {
    maxHeight: "100%",
    maxWidth: "100%",
  },
  productTitle: {
    fontSize: "18px",
    textAlign: "center",
    color: theme.palette.text.secondary,
    wordBreak: "break-all",
  },
  productFooterContainer: {
    wordBreak: "break-word",
    padding: theme.spacing(1, 1),
    textAlign: "right",
  },
  linkGoToProductDetail: {
    "&:hover": {
      background: theme.palette.background.default,
    },
  },
}));

const Title = () => {
  return (
    <Typography component="h1" variant="h5">
      Produits
    </Typography>
  );
};

interface IUsePlaceProductsProps {
  loading?: boolean;
  products: Array<IProduct>;
}

const UsePlaceProducts = (props: IUsePlaceProductsProps) => {
  const { loading = false, products } = props;

  const { classes } = useStyles();

  if (!loading && products.length === 0) {
    return (
      <Paper className={classes.paper}>
        <Title />
        <div className={classes.noLinkeds}>
          <div>Aucun lieu d'utilisation lié au produit.</div>
        </div>
      </Paper>
    );
  }

  return (
    <Paper className={classes.paper}>
      {loading ? (
        <PageLoader />
      ) : (
        <>
          <Title />
          <Grid
            className={classes.productsToUsePlaceContainer}
            container
            spacing={4}
          >
            {products.map((product) => (
              <Grid item key={product.id} lg={4} md={6} xs={12}>
                <Paper elevation={0}>
                  <LinkUi
                    to={`/products/${product.id}`}
                    component={Link}
                    underline="none"
                  >
                    <div className={classes.linkGoToProductDetail}>
                      <div className={classes.productImageWrapper}>
                        <ProductImage
                          rootStyle={classes.productImage}
                          productId={product.id}
                        />
                      </div>
                      <Typography className={classes.productTitle} variant="h4">
                        {product.name}
                      </Typography>
                    </div>
                  </LinkUi>
                  <Divider />
                  <Grid
                    className={classes.productFooterContainer}
                    container
                    justifyContent="space-between"
                  >
                    AUCUNE ACTION POSSIBLE ACTUELLEMENT
                  </Grid>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Paper>
  );
};

export default UsePlaceProducts;
