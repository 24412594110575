import {
  useMutation,
  UseMutationOptions,
  useQuery,
} from "@tanstack/react-query";
import {
  deleteUsePlace,
  getUsePlace,
  getUsePlacePicture,
  getUsePlaces,
  getUsePlacesHistory,
  patchUsePlace,
  postUsePlace,
} from "services/UsePlacesService";
import { TFilter, TJoin } from "types/query.type";
import { AxiosError, AxiosResponse } from "axios";
import { IWarehouse } from "src/types/warehouse.type";
import { IUsePlace } from "src/types/use-place.type";

interface IUseUsePlacesProps {
  join?: TJoin;
  filter?: TFilter;
}

export const useUsePlaces = (props: IUseUsePlacesProps) => {
  const { join = "", filter = "" } = props;

  const { data, ...rest } = useQuery({
    queryKey: ["use-places", { join, filter }],
    queryFn: async () => getUsePlaces(join, filter),
  });

  return {
    usePlaces: data?.data?.data || [],
    ...rest,
  };
};

interface IUseUsePlacesLinkedToWarehouse {
  warehouseId: IWarehouse["id"];
}

export const useUsePlacesLinkedToWarehouse = (
  props: IUseUsePlacesLinkedToWarehouse,
) => {
  const { warehouseId } = props;

  const { data, ...rest } = useQuery({
    queryKey: ["use-places-linked-to-warehouse", { warehouseId }],
    queryFn: async () =>
      getUsePlaces("warehouse", `warehouse.id||eq||${warehouseId}`),
  });

  return {
    usePlaces: data?.data?.data || [],
    ...rest,
  };
};

type TUseCreateUsePlaceConfig = UseMutationOptions<
  AxiosResponse<IUsePlace>,
  AxiosError,
  { usePlace: FormData }
>;

export const useCreateUsePlace = (config: TUseCreateUsePlaceConfig) =>
  useMutation({
    mutationFn: ({ usePlace }) => postUsePlace(usePlace),
    ...config,
  });

type TUseEditUsePlaceConfig = UseMutationOptions<
  AxiosResponse<IUsePlace>,
  AxiosError,
  { usePlaceId: IUsePlace["id"]; usePlace: FormData }
>;

export const useEditUsePlace = (config: TUseEditUsePlaceConfig) =>
  useMutation({
    mutationFn: ({ usePlaceId, usePlace }) =>
      patchUsePlace(usePlaceId, usePlace),
    ...config,
  });

interface IUseUsePlaceProps {
  usePlaceId: IUsePlace["id"];
}

export const useUsePlace = (props: IUseUsePlaceProps) => {
  const { usePlaceId } = props;

  const { data, ...rest } = useQuery({
    queryKey: ["use-place", { usePlaceId }],
    queryFn: async () => getUsePlace(usePlaceId),
  });

  return { usePlace: data?.data || {}, ...rest };
};

interface IUseUsePlacePictureProps {
  usePlaceId: IUsePlace["id"];
}

export const useUsePlacePicture = (props: IUseUsePlacePictureProps) => {
  const { usePlaceId } = props;

  const { data, ...rest } = useQuery({
    queryKey: ["use-place-picture", { usePlaceId }],
    queryFn: async () => getUsePlacePicture(usePlaceId),
  });

  return { usePlacePicture: data?.data || null, ...rest };
};

type TUseDeleteUsePlaceConfig = UseMutationOptions<
  AxiosResponse<void>,
  AxiosError,
  { usePlaceId: IUsePlace["id"] }
>;

export const useDeleteUsePlace = (config: TUseDeleteUsePlaceConfig) =>
  useMutation({
    mutationFn: ({ usePlaceId }) => deleteUsePlace(usePlaceId),
    ...config,
  });

interface IUseUsePlacesHistoryProps {
  usePlaceId: IUsePlace["id"];
}

export const useUsePlacesHistory = (props: IUseUsePlacesHistoryProps) => {
  const { usePlaceId } = props;

  const { data, ...rest } = useQuery({
    queryKey: ["use-places-history", { usePlaceId }],
    queryFn: async () => getUsePlacesHistory(usePlaceId),
  });

  return {
    usePlacesHistory: data?.data?.data || [],
    ...rest,
  };
};
