import axios from "axios";
import config from "../config";
import { TFilter, TJoin } from "types/query.type";
import { IUsePlace } from "src/types/use-place.type";

export const getUsePlaces = (join: TJoin, filter: TFilter) =>
  axios.get(`${config.API.HOST}/use-places`, {
    params: {
      join,
      filter,
    },
  });

export const postUsePlace = (usePlace: FormData) =>
  axios.post(`${config.API.HOST}/use-places`, usePlace);

export const patchUsePlace = (
  usePlaceId: IUsePlace["id"],
  usePlaceFormData: FormData,
) =>
  axios.patch(`${config.API.HOST}/use-places/${usePlaceId}`, usePlaceFormData);

export const getUsePlace = (usePlaceId: IUsePlace["id"]) =>
  axios.get(`${config.API.HOST}/use-places/${usePlaceId}`);

export const getUsePlacePicture = (usePlaceId: IUsePlace["id"]) =>
  axios.get(`${config.API.HOST}/use-places/${usePlaceId}/picture`, {
    responseType: "blob",
  });

export const deleteUsePlace = (usePlaceId: IUsePlace["id"]) =>
  axios.delete(`${config.API.HOST}/use-places/${usePlaceId}`);

export const getUsePlacesHistory = (usePlaceId: IUsePlace["id"]) =>
  axios.get(`${config.API.HOST}/actionshistory`, {
    params: {
      join: "product||true,usePlace,warehouse",
      filter: [`usePlaceId||eq||${usePlaceId}`, "sourceType||eq||USE_PLACE"],
      sort: "createdAt,ASC",
    },
  });
