import MainLayout from "../../layouts/mainLayout/MainLayout";
import StoragePlacesListing from "components/StoragePlaces/StoragePlacesListing";

interface IHomeStoragePlacesProps {
  random: number;
}

const HomeStoragePlaces = (props: IHomeStoragePlacesProps) => {
  const { random } = props;

  return (
    <MainLayout>
      <StoragePlacesListing key={random.toString()} />
    </MainLayout>
  );
};

export default HomeStoragePlaces;
