import axios from "axios";
import config from "../config";
import { TFilter, TJoin } from "types/query.type";
import { IStoragePlace } from "src/types/storage-place.type";

export const getStoragePlaces = (join: TJoin, filter: TFilter) =>
  axios.get(`${config.API.HOST}/storage-places`, {
    params: {
      join,
      filter,
    },
  });

export const postStoragePlace = (storagePlace: FormData) =>
  axios.post(`${config.API.HOST}/storage-places`, storagePlace);

export const patchStoragePlace = (
  storagePlaceId: IStoragePlace["id"],
  storagePlaceFormData: FormData,
) =>
  axios.patch(
    `${config.API.HOST}/storage-places/${storagePlaceId}`,
    storagePlaceFormData,
  );

export const getStoragePlace = (storagePlaceId: IStoragePlace["id"]) =>
  axios.get(`${config.API.HOST}/storage-places/${storagePlaceId}`);

export const getStoragePlacePicture = (storagePlaceId: IStoragePlace["id"]) =>
  axios.get(`${config.API.HOST}/storage-places/${storagePlaceId}/picture`, {
    responseType: "blob",
  });

export const deleteStoragePlace = (storagePlaceId: IStoragePlace["id"]) =>
  axios.delete(`${config.API.HOST}/storage-places/${storagePlaceId}`);

export const getStoragePlacesHistory = (storagePlaceId: IStoragePlace["id"]) =>
  axios.get(`${config.API.HOST}/actionshistory`, {
    params: {
      join: "product||true,storagePlace,warehouse",
      filter: [
        `storagePlaceId||eq||${storagePlaceId}`,
        "sourceType||eq||STORAGE_PLACE",
      ],
      sort: "createdAt,ASC",
    },
  });
