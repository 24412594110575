import {
  useMutation,
  UseMutationOptions,
  useQuery,
} from "@tanstack/react-query";
import {
  addProductToAStoragePlace,
  addProductToAUsePlace,
  deleteProduct,
  getProduct,
  getProductPicture,
  getProducts,
  getProductsHistory,
  patchProduct,
  postProduct,
} from "../services/ProductsService";
import { TFilter, TJoin } from "types/query.type";
import { IProduct } from "types/product.type";
import { AxiosError, AxiosResponse } from "axios";
import { IStoragePlace } from "src/types/storage-place.type";
import { IUsePlace } from "types/use-place.type";

interface IUseProductsProps {
  join?: TJoin;
  filter?: TFilter;
}

export const useProducts = (props: IUseProductsProps) => {
  const { join = "", filter = "" } = props;

  const { data, ...rest } = useQuery({
    queryKey: ["products", { join, filter }],
    queryFn: async () => getProducts(join, filter),
  });

  return {
    products: data?.data?.data || [],
    ...rest,
  };
};

interface IUseProductProps {
  productId: IProduct["id"];
  join?: TJoin;
}

export const useProduct = (props: IUseProductProps) => {
  const { productId, join = "" } = props;

  const { data, ...rest } = useQuery({
    queryKey: ["product", { productId }],
    queryFn: async () => getProduct(productId, join),
  });

  return { product: data?.data || {}, ...rest };
};

type TUseCreateProductConfig = UseMutationOptions<
  AxiosResponse<IProduct>,
  AxiosError,
  { product: FormData }
>;

export const useCreateProduct = (config: TUseCreateProductConfig) =>
  useMutation({
    mutationFn: ({ product }) => postProduct(product),
    ...config,
  });

type TUseEditProductConfig = UseMutationOptions<
  AxiosResponse<IProduct>,
  AxiosError,
  { productId: IProduct["id"]; product: FormData }
>;

export const useEditProduct = (config: TUseEditProductConfig) =>
  useMutation({
    mutationFn: ({ productId, product }) => patchProduct(productId, product),
    ...config,
  });

interface IUseProductPictureProps {
  productId: IProduct["id"];
}

export const useProductPicture = (props: IUseProductPictureProps) => {
  const { productId } = props;

  const { data, ...rest } = useQuery({
    queryKey: ["product-picture", { productId }],
    queryFn: async () => getProductPicture(productId),
  });

  return { productPicture: data?.data || null, ...rest };
};

type TUseDeleteProductConfig = UseMutationOptions<
  AxiosResponse<void>,
  AxiosError,
  { productId: IProduct["id"] }
>;

export const useDeleteProduct = (config: TUseDeleteProductConfig) =>
  useMutation({
    mutationFn: ({ productId }) => deleteProduct(productId),
    ...config,
  });

interface IUseProductsHistoryProps {
  productId: IProduct["id"];
}

export const useProductsHistory = (props: IUseProductsHistoryProps) => {
  const { productId } = props;

  const { data, ...rest } = useQuery({
    queryKey: ["products-history", { productId }],
    queryFn: async () => getProductsHistory(productId),
  });

  return {
    productsHistory: data?.data?.data || [],
    ...rest,
  };
};

type TUseAddProductToAStoragePlaceConfig = UseMutationOptions<
  AxiosResponse<void>,
  AxiosError,
  { productId: IProduct["id"]; storagePlaceId: IStoragePlace["id"] }
>;

export const useAddProductToAStoragePlace = (
  config: TUseAddProductToAStoragePlaceConfig,
) =>
  useMutation({
    mutationFn: ({ productId, storagePlaceId }) =>
      addProductToAStoragePlace(productId, storagePlaceId),
    ...config,
  });

type TUseAddProductToAUsePlaceConfig = UseMutationOptions<
  AxiosResponse<void>,
  AxiosError,
  { productId: IProduct["id"]; usePlaceId: IUsePlace["id"] }
>;

export const useAddProductToAUsePlace = (
  config: TUseAddProductToAUsePlaceConfig,
) =>
  useMutation({
    mutationFn: ({ productId, usePlaceId }) =>
      addProductToAUsePlace(productId, usePlaceId),
    ...config,
  });
