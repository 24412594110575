import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import MainLayout from "../../layouts/mainLayout/MainLayout";
import CreateOrUpdateWarehouse from "../../components/Warehouses/CreateOrUpdateWarehouse";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import SnackbarCloseIcon from "../../components/Snackbar/SnackbarCloseIcon";
import ResourceHistory from "../../components/ResourceHistory/ResourceHistory";
import {
  useDeleteWarehouse,
  useWarehouse,
  useWarehousesHistory,
} from "../../Hooks/warehouses.hook";
import { useStoragePlaces } from "../../Hooks/storage-places.hook";
import { IWarehouse } from "types/warehouse.type";
import { AxiosError, AxiosResponse } from "axios";
import WarehouseStoragePlaces from "components/Warehouses/WarehouseStoragePlaces";
import DeleteWarehouseStoragePlacesLinkedsModal from "components/Warehouses/DeleteWarehouseStoragePlacesLinkedsModal";
import WarehouseUsePlaces from "components/Warehouses/WarehouseUsePlaces";
import { useUsePlaces } from "Hooks/use-places.hook";
import { IStoragePlace } from "src/types/storage-place.type";
import { IUsePlace } from "src/types/use-place.type";

const WarehouseDetail = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { warehouseId = "" } = useParams<{
    warehouseId: IWarehouse["id"];
  }>();
  const navigate = useNavigate();

  const defaultPlacesLinkedsDelete = {
    storagePlacesLinkeds: [],
    usePlacesLinkeds: [],
  };

  const [showConfirmDeleteWarehouse, setShowConfirmDeleteWarehouse] =
    useState(false);
  const [placesLinkedsDelete, setPlacesLinkedsDelete] = useState<{
    storagePlacesLinkeds: Array<IStoragePlace>;
    usePlacesLinkeds: Array<IUsePlace>;
  }>(defaultPlacesLinkedsDelete);

  const {
    warehouse,
    isFetching: isFetchingWarehouse,
    isError: isErrorWarehouse,
    error: errorWarehouse,
  } = useWarehouse({
    warehouseId,
  });

  const { mutate: deleteWarehouse, isPending: isPendingDeleteWarehouse } =
    useDeleteWarehouse({
      onSuccess: () => {
        enqueueSnackbar(`L'entrepôt "${warehouse.name}" a été supprimé.`, {
          variant: "success",
          action: (snackbarKey) => (
            <SnackbarCloseIcon snackbarKey={snackbarKey} />
          ),
        });
        navigate("/warehouses");
      },
      onError: ({ response }) => {
        const { data } = response as AxiosResponse;

        const warehouseHaveStoragePlacesLinkeds =
          data?.title?.error &&
          data?.title?.message &&
          data?.title?.placesLinkeds &&
          (data?.title?.placesLinkeds?.storagePlacesLinkeds ||
            data?.title?.placesLinkeds?.usePlacesLinkeds) &&
          data?.title?.error === "WAREHOUSE_HAVE_STORAGE_OR_USE_PLACES_LINKEDS";
        if (warehouseHaveStoragePlacesLinkeds) {
          setPlacesLinkedsDelete(data.title.placesLinkeds);
        } else {
          enqueueSnackbar(
            `Une erreur est survenue lors de la suppression de l'entrepôt "${warehouse.name}".`,
            {
              variant: "error",
              action: (snackbarKey) => (
                <SnackbarCloseIcon snackbarKey={snackbarKey} />
              ),
            },
          );
        }
      },
    });

  const {
    warehousesHistory,
    isFetching: isFetchingWarehousesHistory,
    isError: isErrorWarehousesHistory,
  } = useWarehousesHistory({
    warehouseId,
  });

  const {
    storagePlaces: storagePlacesLinkeds,
    isFetching: isFetchingStoragePlacesLinkeds,
    isError: isErrorStoragePlacesLinkeds,
  } = useStoragePlaces({
    join: "warehouse",
    filter: `warehouse.id||eq||${warehouseId}`,
  });

  const {
    usePlaces: usePlacesLinkeds,
    isFetching: isFetchingUsePlacesLinkeds,
    isError: isErrorUsePlacesLinkeds,
  } = useUsePlaces({
    join: "warehouse",
    filter: `warehouse.id||eq||${warehouseId}`,
  });

  useEffect(() => {
    if (isErrorWarehouse) {
      const warehouseNotFound =
        (errorWarehouse as AxiosError)?.response?.status === 404;

      enqueueSnackbar(
        warehouseNotFound
          ? `L'entrepôt "${warehouseId}" est introuvable.`
          : "Une erreur est survenue lors de la récupération de l'entrepôt.",
        {
          variant: warehouseNotFound ? "warning" : "error",
          action: (key) => <SnackbarCloseIcon snackbarKey={key} />,
        },
      );
      navigate("/warehouses");
    }
  }, [enqueueSnackbar, isErrorWarehouse, navigate, warehouseId]);

  useEffect(() => {
    if (isErrorWarehousesHistory) {
      enqueueSnackbar(
        "Une erreur est survenue lors de la récupération de l'historique de l'entrepôt.",
        {
          variant: "error",
          action: (key) => <SnackbarCloseIcon snackbarKey={key} />,
        },
      );
    }
  }, [isErrorWarehousesHistory, enqueueSnackbar]);

  useEffect(() => {
    if (isErrorStoragePlacesLinkeds) {
      enqueueSnackbar(
        "Une erreur est survenue lors de la récupération des lieux de stockage liés à l'entrepôt.",
        {
          variant: "error",
          action: (key) => <SnackbarCloseIcon snackbarKey={key} />,
        },
      );
    }
  }, [isErrorStoragePlacesLinkeds, enqueueSnackbar, warehouse.name]);

  useEffect(() => {
    if (isErrorUsePlacesLinkeds) {
      enqueueSnackbar(
        "Une erreur est survenue lors de la récupération des lieux d'utilisation liés à l'entrepôt.",
        {
          variant: "error",
          action: (key) => <SnackbarCloseIcon snackbarKey={key} />,
        },
      );
    }
  }, [isErrorUsePlacesLinkeds, enqueueSnackbar, warehouse.name]);

  const handleChangeConfirmDeleteWarehouse = () => {
    if (!isPendingDeleteWarehouse) {
      setShowConfirmDeleteWarehouse(!showConfirmDeleteWarehouse);
    }
  };

  const hancleCloseDeleteWarehouseStoragePlacesLinkeds = () => {
    setShowConfirmDeleteWarehouse(false);
    setPlacesLinkedsDelete(defaultPlacesLinkedsDelete);
  };

  return (
    <MainLayout>
      {showConfirmDeleteWarehouse && (
        <DeleteWarehouseStoragePlacesLinkedsModal
          storagePlacesLinkeds={placesLinkedsDelete.storagePlacesLinkeds}
          usePlacesLinkeds={placesLinkedsDelete.usePlacesLinkeds}
          warehouse={warehouse}
          hancleClose={hancleCloseDeleteWarehouseStoragePlacesLinkeds}
        />
      )}
      {showConfirmDeleteWarehouse && (
        <ConfirmationModal
          key="delete-warehouse-confirmation-modal"
          open
          loadingSuccessCallback={isPendingDeleteWarehouse}
          title="Suppression d'un entrepôt."
          description={`Voulez-vous vraiment supprimer l'entrepôt "${warehouse.name}" ?`}
          handleClose={handleChangeConfirmDeleteWarehouse}
          yesCallback={() => deleteWarehouse({ warehouseId })}
        />
      )}
      <Grid container spacing={4}>
        <Grid item xs={12} container justifyContent="flex-end">
          <Button
            onClick={handleChangeConfirmDeleteWarehouse}
            variant="contained"
            color="secondary"
            disabled={isFetchingWarehouse}
          >
            Supprimer
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item xs={12} lg={6} xl={4}>
              <CreateOrUpdateWarehouse
                warehouseUpdate={warehouse}
                title={
                  warehouse && warehouse.name
                    ? `Mise à jour de "${warehouse.name}"`
                    : ""
                }
                loading={isFetchingWarehouse}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <WarehouseStoragePlaces
            loading={isFetchingStoragePlacesLinkeds}
            storagePlaces={storagePlacesLinkeds}
          />
        </Grid>
        <Grid item xs={12}>
          <WarehouseUsePlaces
            loading={isFetchingUsePlacesLinkeds}
            usePlaces={usePlacesLinkeds}
          />
        </Grid>
        <Grid item xs={12}>
          <ResourceHistory
            history={warehousesHistory}
            loading={isFetchingWarehousesHistory}
          />
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default WarehouseDetail;
