import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { Grid } from "@mui/material";
import MainLayout from "../../layouts/mainLayout/MainLayout";
import CreateOrUpdateUsePlace from "components/UsePlaces/CreateOrUpdateUsePlace";
import SnackbarCloseIcon from "../../components/Snackbar/SnackbarCloseIcon";
import { useWarehouses } from "../../Hooks/warehouses.hook";

const CreateUsePlace = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { warehouses, isFetching, isError } = useWarehouses({});

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(
        "Une erreur est survenue lors de la récupération des entrepôts.",
        {
          variant: "error",
          action: (snackbarKey) => (
            <SnackbarCloseIcon snackbarKey={snackbarKey} />
          ),
        },
      );
    }
  }, [enqueueSnackbar, isError]);

  return (
    <MainLayout>
      <Grid container spacing={16} justifyContent="center">
        <Grid item xs={12} sm={11} md={8} lg={6} xl={4}>
          <CreateOrUpdateUsePlace
            title="Ajouter un lieu d'utilisation"
            warehouses={warehouses}
            loading={isFetching}
          />
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default CreateUsePlace;
