import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import {
  deleteWarehouse,
  getWarehouse,
  getWarehousePicture,
  getWarehouses,
  getWarehousesHistory,
  patchWarehouse,
  postWarehouse,
} from "../services/WarehousesService";
import { AxiosError, AxiosResponse } from "axios";
import { IWarehouse } from "types/warehouse.type";
import { IStockHistory } from "types/stock.type";

interface IUseWarehousesProps {
  join?: string;
  filter?: string;
}

export const useWarehouses = (props: IUseWarehousesProps) => {
  const { join = "", filter = "" } = props;

  const { data, ...rest } = useQuery({
    queryKey: ["warehouses", { join, filter }],
    queryFn: async () => getWarehouses(join, filter),
  });

  return {
    warehouses: data?.data?.data || [],
    ...rest,
  };
};

type TUseCreateWarehouseConfig = UseMutationOptions<
  AxiosResponse<IWarehouse>,
  AxiosError,
  { warehouse: FormData }
>;

export const useCreateWarehouse = (config: TUseCreateWarehouseConfig) =>
  useMutation({
    mutationFn: ({ warehouse }) => postWarehouse(warehouse),
    ...config,
  });

type TUseEditWarehouseConfig = UseMutationOptions<
  AxiosResponse<IWarehouse>,
  AxiosError,
  { warehouseId: IWarehouse["id"]; warehouse: FormData }
>;

export const useEditWarehouse = (config: TUseEditWarehouseConfig) =>
  useMutation({
    mutationFn: ({ warehouseId, warehouse }) =>
      patchWarehouse(warehouseId, warehouse),
    ...config,
  });

interface IUseWarehouseProps {
  warehouseId: IWarehouse["id"];
}

export const useWarehouse = (props: IUseWarehouseProps) => {
  const { warehouseId } = props;

  const { data, ...rest } = useQuery({
    queryKey: ["warehouse", { warehouseId }],
    queryFn: async () => getWarehouse(warehouseId),
  });

  return { warehouse: data?.data || {}, ...rest };
};

interface IUseWarehousePictureProps {
  warehouseId: IWarehouse["id"];
}

export const useWarehousePicture = (props: IUseWarehousePictureProps) => {
  const { warehouseId } = props;

  const { data, ...rest } = useQuery({
    queryKey: ["warehouse-picture", { warehouseId }],
    queryFn: async () => getWarehousePicture(warehouseId),
  });

  return { warehousePicture: data?.data || null, ...rest };
};

type TUseDeleteWarehouseConfig = UseMutationOptions<
  AxiosResponse<void>,
  AxiosError,
  { warehouseId: IWarehouse["id"] }
>;

export const useDeleteWarehouse = (config: TUseDeleteWarehouseConfig) =>
  useMutation({
    mutationFn: ({ warehouseId }) => deleteWarehouse(warehouseId),
    ...config,
  });

interface IUseWarehousesHistoryProps {
  warehouseId: IWarehouse["id"];
}

type TWarehouseHistoryQuery = Omit<
  UseQueryResult<AxiosResponse<IStockHistory>>,
  "data"
> & {
  warehousesHistory: Array<IStockHistory>;
};

export const useWarehousesHistory = (
  props: IUseWarehousesHistoryProps,
): TWarehouseHistoryQuery => {
  const { warehouseId } = props;

  const { data, ...rest } = useQuery({
    queryKey: ["warehouses-history", { warehouseId }],
    queryFn: async () => getWarehousesHistory(warehouseId),
  });

  return {
    warehousesHistory: data?.data?.data || [],
    ...rest,
  };
};
