import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Grid, Button } from "@mui/material";
import MainLayout from "../../layouts/mainLayout/MainLayout";
import CreateOrUpdateUsePlace from "components/UsePlaces/CreateOrUpdateUsePlace";
import ConfirmationModal from "components/Modals/ConfirmationModal";
import SnackbarCloseIcon from "components/Snackbar/SnackbarCloseIcon";
import UsePlaceProducts from "components/UsePlaces/UsePlaceProducts";
import ResourceHistory from "../../components/ResourceHistory/ResourceHistory";
import UsePlaceLinkedToProductsModal from "components/UsePlaces/UsePlaceLinkedToProductsModal";
import { useProducts } from "Hooks/products.hook";
import { useWarehouses } from "Hooks/warehouses.hook";
import {
  useDeleteUsePlace,
  useUsePlace,
  useUsePlacesHistory,
} from "../../Hooks/use-places.hook";
import { AxiosError, AxiosResponse } from "axios";
import { IUsePlace } from "src/types/use-place.type";

const UsePlaceDetail = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { usePlaceId = "" } = useParams<{
    usePlaceId: IUsePlace["id"];
  }>();
  const navigate = useNavigate();

  const [showConfirmDeleteUsePlace, setShowConfirmDeleteUsePlace] =
    useState(false);
  const [productsLinkeds, setProductsLinkeds] = useState([]);

  const {
    products,
    isFetching: isFetchingProducts,
    isError: isErrorProducts,
  } = useProducts({
    join: "usePlaces",
    filter: `usePlaces.id||eq||${usePlaceId}`,
  });

  const {
    warehouses,
    isFetching: isFetchingWarehouses,
    isError: isErrorWarehouses,
  } = useWarehouses({});

  const {
    usePlace,
    isFetching: isFetchingUsePlace,
    isError: isErrorUsePlace,
    error: errorUsePlace,
  } = useUsePlace({
    usePlaceId,
  });

  const { mutate: deleteUsePlace, isPending: isPendingDeleteUsePlace } =
    useDeleteUsePlace({
      onSuccess: () => {
        enqueueSnackbar(
          `Le lieu d'utilisation "${usePlace.name}" a été supprimé.`,
          {
            variant: "success",
            action: (snackbarKey) => (
              <SnackbarCloseIcon snackbarKey={snackbarKey} />
            ),
          },
        );
        navigate("/use-places");
      },
      onError: ({ response }) => {
        const { data } = response as AxiosResponse;

        const usePlaceHaveProductsLinkeds =
          data &&
          data.title &&
          data.title.error &&
          data.title.message &&
          data.title.productsLinkeds &&
          data.title.error === "USE_PLACE_HAVE_PRODUCTS_LINKEDS";
        if (usePlaceHaveProductsLinkeds) {
          setShowConfirmDeleteUsePlace(false);
          setProductsLinkeds(data.title.productsLinkeds);
        } else {
          enqueueSnackbar(
            `Une erreur est survenue lors de la suppression du lieu d'utilisation "${usePlace.name}".`,
            {
              variant: "error",
              action: (snackbarKey) => (
                <SnackbarCloseIcon snackbarKey={snackbarKey} />
              ),
            },
          );
        }
      },
    });

  const {
    usePlacesHistory,
    isFetching: isFetchingUsePlacesHistory,
    isError: isErrorUsePlacesHistory,
    refetch: refetchUsePlacesHistory,
  } = useUsePlacesHistory({
    usePlaceId,
  });

  useEffect(() => {
    if (isErrorProducts) {
      enqueueSnackbar(
        "Une erreur est survenue lors de la récupération des produits liés au lieu d'utilisation.",
        {
          variant: "error",
          action: (key) => <SnackbarCloseIcon snackbarKey={key} />,
        },
      );
    }
  }, [enqueueSnackbar, isErrorProducts]);

  useEffect(() => {
    if (isErrorWarehouses) {
      enqueueSnackbar(
        "Une erreur est survenue lors de la récupération des entrepôts. Impossible de modifier le lieu d'utilisation.",
        {
          variant: "error",
          action: (snackbarKey) => (
            <SnackbarCloseIcon snackbarKey={snackbarKey} />
          ),
        },
      );
      navigate("/use-places");
    }
  }, [enqueueSnackbar, isErrorWarehouses, navigate]);

  useEffect(() => {
    if (isErrorUsePlace) {
      const usePlaceNotFound =
        (errorUsePlace as AxiosError)?.response?.status === 404;

      enqueueSnackbar(
        usePlaceNotFound
          ? `Le lieu d'utilisation "${usePlaceId}" est introuvable.`
          : "Une erreur est survenue lors de la récupération du lieu d'utilisation.",
        {
          variant: usePlaceNotFound ? "warning" : "error",
          action: (key) => <SnackbarCloseIcon snackbarKey={key} />,
        },
      );
      navigate("/use-places");
    }
  }, [enqueueSnackbar, isErrorUsePlace, navigate, usePlaceId]);

  useEffect(() => {
    if (isErrorUsePlacesHistory) {
      enqueueSnackbar(
        "Une erreur est survenue lors de la récupération de l'historique du lieu d'utilisation.",
        {
          variant: "error",
          action: (key) => <SnackbarCloseIcon snackbarKey={key} />,
        },
      );
    }
  }, [isErrorUsePlacesHistory, enqueueSnackbar]);

  const handleChangeConfirmDeleteUsePlace = () => {
    if (!isPendingDeleteUsePlace) {
      setShowConfirmDeleteUsePlace(!showConfirmDeleteUsePlace);
    }
  };

  return (
    <MainLayout>
      {productsLinkeds.length > 0 && (
        <UsePlaceLinkedToProductsModal
          title={`Il n'est pas possible de supprimer le lieu d'utilisation "${usePlace.name}".`}
          productsLinkeds={productsLinkeds}
          usePlace={usePlace}
          handleClose={() => setProductsLinkeds([])}
          transitionDurationEnter={0}
        />
      )}
      {showConfirmDeleteUsePlace && (
        <ConfirmationModal
          key="delete-place-confirmation-modal"
          open
          loadingSuccessCallback={isPendingDeleteUsePlace}
          title="Suppression d&#39;un lieu d'utilisation."
          description={`Voulez-vous vraiment supprimer le lieu d'utilisation "${usePlace.name}" ?`}
          handleClose={handleChangeConfirmDeleteUsePlace}
          yesCallback={() => deleteUsePlace({ usePlaceId })}
        />
      )}
      <Grid container spacing={4}>
        <Grid item xs={12} container justifyContent="flex-end">
          <Button
            onClick={handleChangeConfirmDeleteUsePlace}
            variant="contained"
            color="secondary"
            disabled={isFetchingUsePlace || isFetchingWarehouses}
          >
            Supprimer
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item xs={12} lg={6} xl={4}>
              <CreateOrUpdateUsePlace
                usePlace={usePlace}
                title={
                  usePlace && usePlace.name
                    ? `Mise à jour de "${usePlace.name}"`
                    : ""
                }
                callback={() => refetchUsePlacesHistory()}
                loading={isFetchingUsePlace || isFetchingWarehouses}
                warehouses={warehouses}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <UsePlaceProducts loading={isFetchingProducts} products={products} />
        </Grid>
        <Grid item xs={12}>
          <ResourceHistory
            history={usePlacesHistory}
            loading={isFetchingUsePlacesHistory}
          />
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default UsePlaceDetail;
