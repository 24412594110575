import Typography from "@mui/material/Typography";
import LinkUi from "@mui/material/Link";
import { Link } from "react-router-dom";
// @ts-ignore
import Pluralize from "react-pluralize";
import CloseModal from "../Modals/CloseModal";
import { IWarehouse } from "types/warehouse.type";
import { IStoragePlace } from "src/types/storage-place.type";
import { IUsePlace } from "src/types/use-place.type";

const PluralizeStoragePlacesLinkeds = ({
  storagePlacesLinkeds,
}: {
  storagePlacesLinkeds: Array<IStoragePlace>;
}) => (
  <Pluralize
    singular="lieu de stockage"
    plural="lieux de stockage"
    count={storagePlacesLinkeds.length}
  />
);

const PluralizeUsePlacesLinkeds = ({
  usePlacesLinkeds,
}: {
  usePlacesLinkeds: Array<IUsePlace>;
}) => (
  <Pluralize
    singular="lieu d'utilisation"
    plural="lieux d'utilisation"
    count={usePlacesLinkeds.length}
  />
);

const getLinkedText = ({
  storagePlacesLinkeds,
  usePlacesLinkeds,
}: {
  storagePlacesLinkeds: Array<IStoragePlace>;
  usePlacesLinkeds: Array<IUsePlace>;
}) => {
  const text = "Il est lié à";

  if (storagePlacesLinkeds.length > 0 && usePlacesLinkeds.length > 0) {
    return (
      <>
        {text}{" "}
        <PluralizeStoragePlacesLinkeds
          storagePlacesLinkeds={storagePlacesLinkeds}
        />{" "}
        et <PluralizeUsePlacesLinkeds usePlacesLinkeds={usePlacesLinkeds} />.
      </>
    );
  }

  if (storagePlacesLinkeds.length > 0 && usePlacesLinkeds.length === 0) {
    return (
      <>
        {text}{" "}
        <PluralizeStoragePlacesLinkeds
          storagePlacesLinkeds={storagePlacesLinkeds}
        />
        .
      </>
    );
  }

  if (storagePlacesLinkeds.length === 0 && usePlacesLinkeds.length > 0) {
    return (
      <>
        {text} <PluralizeUsePlacesLinkeds usePlacesLinkeds={usePlacesLinkeds} />
        .
      </>
    );
  }

  return "";
};

interface IDeleteWarehouseStoragePlacesLinkedsModalProps {
  warehouse: IWarehouse;
  hancleClose: () => void;
  storagePlacesLinkeds: Array<IStoragePlace>;
  usePlacesLinkeds: Array<IUsePlace>;
}

const DeleteWarehouseStoragePlacesLinkedsModal = (
  props: IDeleteWarehouseStoragePlacesLinkedsModalProps,
) => {
  const { storagePlacesLinkeds, usePlacesLinkeds, warehouse, hancleClose } =
    props;

  return (
    (storagePlacesLinkeds.length > 0 || usePlacesLinkeds.length > 0) && (
      <CloseModal
        key="delete-warehouse-confirmation-modal"
        open
        title={`Suppression d'un entrepôt.`}
        handleClose={hancleClose}
      >
        <Typography>{`Il n'est pas possible de supprimer l'entrepôt "${warehouse.name}".`}</Typography>
        <Typography>
          {getLinkedText({ storagePlacesLinkeds, usePlacesLinkeds })}
        </Typography>
        {storagePlacesLinkeds.length > 0 && [
          <Typography key="storage-places-linkeds-title">
            <PluralizeStoragePlacesLinkeds
              storagePlacesLinkeds={storagePlacesLinkeds}
            />{" "}
            :
          </Typography>,
          <ul>
            {storagePlacesLinkeds.map((storagePlaceLinked) => (
              <li key={storagePlaceLinked.id}>
                <LinkUi
                  to={`/storage-places/${storagePlaceLinked.id}`}
                  component={Link}
                  underline="none"
                >
                  {storagePlaceLinked.name}
                </LinkUi>
              </li>
            ))}
          </ul>,
        ]}
        {usePlacesLinkeds.length > 0 && [
          <Typography key="storage-places-linkeds-title">
            <PluralizeUsePlacesLinkeds usePlacesLinkeds={usePlacesLinkeds} /> :
          </Typography>,
          <ul>
            {usePlacesLinkeds.map((usePlaceLinked) => (
              <li key={usePlaceLinked.id}>
                <LinkUi
                  to={`/use-places/${usePlaceLinked.id}`}
                  component={Link}
                  underline="none"
                >
                  {usePlaceLinked.name}
                </LinkUi>
              </li>
            ))}
          </ul>,
        ]}
      </CloseModal>
    )
  );
};

export default DeleteWarehouseStoragePlacesLinkedsModal;
