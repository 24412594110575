import {
  useMutation,
  UseMutationOptions,
  useQuery,
} from "@tanstack/react-query";
import {
  deleteStoragePlace,
  getStoragePlace,
  getStoragePlacePicture,
  getStoragePlaces,
  getStoragePlacesHistory,
  patchStoragePlace,
  postStoragePlace,
} from "services/StoragePlacesService";
import { TFilter, TJoin } from "types/query.type";
import { AxiosError, AxiosResponse } from "axios";
import { IWarehouse } from "src/types/warehouse.type";
import { IStoragePlace } from "src/types/storage-place.type";

interface IUseStoragePlacesProps {
  join?: TJoin;
  filter?: TFilter;
}

export const useStoragePlaces = (props: IUseStoragePlacesProps) => {
  const { join = "", filter = "" } = props;

  const { data, ...rest } = useQuery({
    queryKey: ["storage-places", { join, filter }],
    queryFn: async () => getStoragePlaces(join, filter),
  });

  return {
    storagePlaces: data?.data?.data || [],
    ...rest,
  };
};

interface IUseStoragePlacesLinkedToWarehouse {
  warehouseId: IWarehouse["id"];
}

export const useStoragePlacesLinkedToWarehouse = (
  props: IUseStoragePlacesLinkedToWarehouse,
) => {
  const { warehouseId } = props;

  const { data, ...rest } = useQuery({
    queryKey: ["storage-places-linked-to-warehouse", { warehouseId }],
    queryFn: async () =>
      getStoragePlaces("warehouse", `warehouse.id||eq||${warehouseId}`),
  });

  return {
    storagePlaces: data?.data?.data || [],
    ...rest,
  };
};

type TUseCreateStoragePlaceConfig = UseMutationOptions<
  AxiosResponse<IStoragePlace>,
  AxiosError,
  { storagePlace: FormData }
>;

export const useCreateStoragePlace = (config: TUseCreateStoragePlaceConfig) =>
  useMutation({
    mutationFn: ({ storagePlace }) => postStoragePlace(storagePlace),
    ...config,
  });

type TUseEditStoragePlaceConfig = UseMutationOptions<
  AxiosResponse<IStoragePlace>,
  AxiosError,
  { storagePlaceId: IStoragePlace["id"]; storagePlace: FormData }
>;

export const useEditStoragePlace = (config: TUseEditStoragePlaceConfig) =>
  useMutation({
    mutationFn: ({ storagePlaceId, storagePlace }) =>
      patchStoragePlace(storagePlaceId, storagePlace),
    ...config,
  });

interface IUseStoragePlaceProps {
  storagePlaceId: IStoragePlace["id"];
}

export const useStoragePlace = (props: IUseStoragePlaceProps) => {
  const { storagePlaceId } = props;

  const { data, ...rest } = useQuery({
    queryKey: ["storage-place", { storagePlaceId }],
    queryFn: async () => getStoragePlace(storagePlaceId),
  });

  return { storagePlace: data?.data || {}, ...rest };
};

interface IUseStoragePlacePictureProps {
  storagePlaceId: IStoragePlace["id"];
}

export const useStoragePlacePicture = (props: IUseStoragePlacePictureProps) => {
  const { storagePlaceId } = props;

  const { data, ...rest } = useQuery({
    queryKey: ["storage-place-picture", { storagePlaceId }],
    queryFn: async () => getStoragePlacePicture(storagePlaceId),
  });

  return { storagePlacePicture: data?.data || null, ...rest };
};

type TUseDeleteStoragePlaceConfig = UseMutationOptions<
  AxiosResponse<void>,
  AxiosError,
  { storagePlaceId: IStoragePlace["id"] }
>;

export const useDeleteStoragePlace = (config: TUseDeleteStoragePlaceConfig) =>
  useMutation({
    mutationFn: ({ storagePlaceId }) => deleteStoragePlace(storagePlaceId),
    ...config,
  });

interface IUseStoragePlacesHistoryProps {
  storagePlaceId: IStoragePlace["id"];
}

export const useStoragePlacesHistory = (
  props: IUseStoragePlacesHistoryProps,
) => {
  const { storagePlaceId } = props;

  const { data, ...rest } = useQuery({
    queryKey: ["storage-places-history", { storagePlaceId }],
    queryFn: async () => getStoragePlacesHistory(storagePlaceId),
  });

  return {
    storagePlacesHistory: data?.data?.data || [],
    ...rest,
  };
};
