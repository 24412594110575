import Typography from "@mui/material/Typography";
import LinkUi from "@mui/material/Link";
import { Link } from "react-router-dom";
// @ts-ignore
import Pluralize from "react-pluralize";
import CloseModal from "../Modals/CloseModal";
import { IProduct } from "types/product.type";
import { IUsePlace } from "src/types/use-place.type";

interface IUsePlaceLinkedToProductsModalProps {
  transitionDurationEnter?: number;
  title: string;
  handleClose: () => void;
  usePlace?: IUsePlace;
  productsLinkeds: Array<IProduct>;
}

const UsePlaceLinkedToProductsModal = (
  props: IUsePlaceLinkedToProductsModalProps,
) => {
  const {
    transitionDurationEnter = 300,
    title,
    handleClose,
    usePlace,
    productsLinkeds,
  } = props;

  return (
    <CloseModal
      key="use-place-linked-to-product"
      open
      title={`Mise à jour du lieu d'utilisation "${usePlace?.name}".`}
      handleClose={handleClose}
      transitionDurationEnter={transitionDurationEnter}
    >
      <Typography>{title}</Typography>
      <Typography>
        {`Il est lié à `}
        <Pluralize singular="produit" count={productsLinkeds.length} />
        {` :`}
      </Typography>
      <ul>
        {productsLinkeds.map((linked) => (
          <li key={linked.id}>
            <LinkUi
              to={`/products/${linked.id}`}
              component={Link}
              underline="none"
            >
              {linked.name}
            </LinkUi>
          </li>
        ))}
      </ul>
    </CloseModal>
  );
};

export default UsePlaceLinkedToProductsModal;
