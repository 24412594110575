import MainLayout from "../../layouts/mainLayout/MainLayout";
import UsePlacesListing from "components/UsePlaces/UsePlacesListing";

interface IHomeUsePlacesProps {
  random: number;
}

const HomeUsePlaces = (props: IHomeUsePlacesProps) => {
  const { random } = props;

  return (
    <MainLayout>
      <UsePlacesListing key={random.toString()} />
    </MainLayout>
  );
};

export default HomeUsePlaces;
