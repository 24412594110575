import { Link } from "react-router-dom";
import LinkUi from "@mui/material/Link";
import { IStockHistory } from "types/stock.type";
import { IProduct } from "types/product.type";
import { IWarehouse } from "types/warehouse.type";
import { IStoragePlace } from "src/types/storage-place.type";
import { IUsePlace } from "src/types/use-place.type";

export interface IFormatToWordProps {
  actionType: IStockHistory["actionType"];
  productId: IProduct["id"];
  product: IProduct;
  storagePlaceId: IStoragePlace["id"];
  storagePlace: IStoragePlace;
  usePlaceId: IUsePlace["id"];
  usePlace: IUsePlace;
  warehouseId: IWarehouse["id"];
  warehouse: IWarehouse;
}

const formatToWord = ({
  actionType,
  productId,
  product,
  storagePlaceId,
  storagePlace,
  usePlaceId,
  usePlace,
  warehouseId,
  warehouse,
}: IFormatToWordProps): JSX.Element => {
  switch (actionType) {
    case "CREATE_PRODUCT":
      return (
        <>
          Création du produit{" "}
          <LinkUi to={`/products/${productId}`} component={Link}>
            {product?.name || productId}
          </LinkUi>
        </>
      );
    case "CREATE_STORAGE_PLACE":
      return (
        <>
          Création du lieu de stockage{" "}
          <LinkUi to={`/storage-places/${storagePlaceId}`} component={Link}>
            {storagePlace?.name || storagePlaceId}
          </LinkUi>
        </>
      );
    case "CREATE_WAREHOUSE":
      return (
        <>
          Création de l&apos;entrepôt{" "}
          <LinkUi to={`/warehouses/${warehouseId}`} component={Link}>
            {warehouse?.name || warehouseId}
          </LinkUi>
        </>
      );
    case "UPDATE_PRODUCT":
      return (
        <>
          Mise à jour du produit{" "}
          <LinkUi to={`/products/${productId}`} component={Link}>
            {product?.name || productId}
          </LinkUi>
        </>
      );
    case "UPDATE_STORAGE_PLACE":
      return (
        <>
          Mise à jour du lieu de stockage{" "}
          <LinkUi to={`/storage-places/${storagePlaceId}`} component={Link}>
            {storagePlace?.name || storagePlaceId}
          </LinkUi>
        </>
      );
    case "UPDATE_WAREHOUSE":
      return (
        <>
          Mise à jour de l&apos;entrepôt{" "}
          <LinkUi to={`/warehouses/${warehouseId}`} component={Link}>
            {warehouse?.name || warehouseId}
          </LinkUi>
        </>
      );
    case "DELETE_PRODUCT":
      return (
        <>
          Suppression du produit{" "}
          <LinkUi to={`/products/${productId}`} component={Link}>
            {product?.name || productId}
          </LinkUi>
        </>
      );
    case "DELETE_STORAGE_PLACE":
      return (
        <>
          Suppression du lieu de stockage{" "}
          <LinkUi to={`/storage-places/${storagePlaceId}`} component={Link}>
            {storagePlace?.name || storagePlaceId}
          </LinkUi>
        </>
      );
    case "DELETE_WAREHOUSE":
      return (
        <>
          Suppression de l&apos;entrepôt{" "}
          <LinkUi to={`/warehouses/${warehouseId}`} component={Link}>
            {warehouse?.name || warehouseId}
          </LinkUi>
        </>
      );
    case "ADD_STORAGE_PLACE_TO_A_PRODUCT":
      return (
        <>
          Ajout du lieu de stockage{" "}
          <LinkUi to={`/storage-places/${storagePlaceId}`} component={Link}>
            {storagePlace?.name || storagePlaceId}
          </LinkUi>{" "}
          au produit{" "}
          <LinkUi to={`/products/${productId}`} component={Link}>
            {product?.name || productId}
          </LinkUi>
        </>
      );
    case "ADD_WAREHOUSE_TO_STORAGE_PLACE":
      return (
        <>
          Ajout de l&apos;entrepôt{" "}
          <LinkUi to={`/warehouses/${warehouseId}`} component={Link}>
            {warehouse?.name || warehouseId}
          </LinkUi>{" "}
          au lieu de stockage{" "}
          <LinkUi to={`/storage-places/${storagePlaceId}`} component={Link}>
            {storagePlace?.name || storagePlaceId}
          </LinkUi>
        </>
      );
    case "ADD_STORAGE_PLACE_TO_WAREHOUSE":
      return (
        <>
          Ajout du lieu de stockage{" "}
          <LinkUi to={`/storage-places/${storagePlaceId}`} component={Link}>
            {storagePlace?.name || storagePlaceId}
          </LinkUi>{" "}
          à l&apos;entrepôt{" "}
          <LinkUi to={`/warehouses/${warehouseId}`} component={Link}>
            {warehouse?.name || warehouseId}
          </LinkUi>
        </>
      );
    case "DELETE_WAREHOUSE_TO_STORAGE_PLACE":
      return (
        <>
          Suppression de l&apos;entrepôt{" "}
          <LinkUi to={`/warehouses/${warehouseId}`} component={Link}>
            {warehouse?.name || warehouseId}
          </LinkUi>{" "}
          au lieu de stockage{" "}
          <LinkUi to={`/storage-places/${storagePlaceId}`} component={Link}>
            {storagePlace?.name || storagePlaceId}
          </LinkUi>
        </>
      );
    case "DELETE_STORAGE_PLACE_TO_WAREHOUSE":
      return (
        <>
          Suppression du lieu de stockage{" "}
          <LinkUi to={`/storage-places/${storagePlaceId}`} component={Link}>
            {storagePlace?.name || storagePlaceId}
          </LinkUi>{" "}
          à l&apos;entrepôt{" "}
          <LinkUi to={`/warehouses/${warehouseId}`} component={Link}>
            {warehouse?.name || warehouseId}
          </LinkUi>
        </>
      );
    case "ADD_PRODUCT_TO_A_STORAGE_PLACE":
      return (
        <>
          Ajout du produit{" "}
          <LinkUi to={`/products/${productId}`} component={Link}>
            {product?.name || productId}
          </LinkUi>{" "}
          au lieu de stockage{" "}
          <LinkUi to={`/storage-places/${storagePlaceId}`} component={Link}>
            {storagePlace?.name || storagePlaceId}
          </LinkUi>
        </>
      );
    case "ADD_PRODUCT_TO_A_WAREHOUSE":
      return (
        <>
          Ajout du produit{" "}
          <LinkUi to={`/products/${productId}`} component={Link}>
            {product?.name || productId}
          </LinkUi>{" "}
          à l&apos;entrepôt{" "}
          <LinkUi to={`/warehouses/${warehouseId}`} component={Link}>
            {warehouse?.name || warehouseId}
          </LinkUi>
        </>
      );
    case "ADD_WAREHOUSE_TO_A_PRODUCT":
      return (
        <>
          Ajout de l&apos;entrepôt{" "}
          <LinkUi to={`/warehouses/${warehouseId}`} component={Link}>
            {warehouse?.name || warehouseId}
          </LinkUi>{" "}
          au produit{" "}
          <LinkUi to={`/products/${productId}`} component={Link}>
            {product?.name || productId}
          </LinkUi>
        </>
      );
    case "DELETE_PRODUCT_TO_A_STORAGE_PLACE":
      return (
        <>
          Suppression du produit{" "}
          <LinkUi to={`/products/${productId}`} component={Link}>
            {product?.name || productId}
          </LinkUi>{" "}
          au lieu de stockage{" "}
          <LinkUi to={`/storage-places/${storagePlaceId}`} component={Link}>
            {storagePlace?.name || storagePlaceId}
          </LinkUi>
        </>
      );
    case "DELETE_PRODUCT_TO_A_USE_PLACE":
      return (
        <>
          Suppression du produit{" "}
          <LinkUi to={`/products/${productId}`} component={Link}>
            {product?.name || productId}
          </LinkUi>{" "}
          au lieu d'utilisation{" "}
          <LinkUi to={`/use-places/${usePlaceId}`} component={Link}>
            {usePlace?.name || usePlaceId}
          </LinkUi>
        </>
      );
    case "DELETE_PRODUCT_TO_A_WAREHOUSE":
      return (
        <>
          Suppression du produit{" "}
          <LinkUi to={`/products/${productId}`} component={Link}>
            {product?.name || productId}
          </LinkUi>{" "}
          à l&apos;entrepôt{" "}
          <LinkUi to={`/warehouses/${warehouseId}`} component={Link}>
            {warehouse?.name || warehouseId}
          </LinkUi>
        </>
      );
    case "ADD_STOCK_TO_PRODUCT_FOR_PRODUCT_LINK_TO_STORAGE_PLACE":
      return (
        <>
          Ajout de stock au produit{" "}
          <LinkUi to={`/products/${productId}`} component={Link}>
            {product?.name || productId}
          </LinkUi>{" "}
          pour le lieu de stockage{" "}
          <LinkUi to={`/storage-places/${storagePlaceId}`} component={Link}>
            {storagePlace?.name || storagePlaceId}
          </LinkUi>
        </>
      );
    case "ADD_STOCK_TO_PRODUCT_FOR_PRODUCT_LINK_TO_WAREHOUSE":
      return (
        <>
          Ajout de stock au produit{" "}
          <LinkUi to={`/products/${productId}`} component={Link}>
            {product?.name || productId}
          </LinkUi>{" "}
          pour l&apos;entrepôt{" "}
          <LinkUi to={`/warehouses/${warehouseId}`} component={Link}>
            {warehouse?.name || warehouseId}
          </LinkUi>
        </>
      );
    case "ADD_STOCK_TO_PRODUCT_FOR_WAREHOUSE_LINK_BY_PRODUCT":
      return (
        <>
          Ajout de stock au produit{" "}
          <LinkUi to={`/products/${productId}`} component={Link}>
            {product?.name || productId}
          </LinkUi>{" "}
          pour l&apos;entrepôt{" "}
          <LinkUi to={`/warehouses/${warehouseId}`} component={Link}>
            {warehouse?.name || warehouseId}
          </LinkUi>
        </>
      );
    case "ADD_STOCK_TO_PRODUCT_FOR_STORAGE_PLACE_LINK_BY_PRODUCT":
      return (
        <>
          Ajout de stock au produit{" "}
          <LinkUi to={`/products/${productId}`} component={Link}>
            {product?.name || productId}
          </LinkUi>{" "}
          pour le lieu de stockage{" "}
          <LinkUi to={`/storage-places/${storagePlaceId}`} component={Link}>
            {storagePlace?.name || storagePlaceId}
          </LinkUi>
        </>
      );
    case "USE_STOCK_TO_PRODUCT_FOR_PRODUCT_LINK_TO_STORAGE_PLACE":
      return (
        <>
          Utilisation de stock au produit{" "}
          <LinkUi to={`/products/${productId}`} component={Link}>
            {product?.name || productId}
          </LinkUi>{" "}
          pour le lieu de stockage{" "}
          <LinkUi to={`/storage-places/${storagePlaceId}`} component={Link}>
            {storagePlace?.name || storagePlaceId}
          </LinkUi>
        </>
      );
    case "USE_STOCK_TO_PRODUCT_FOR_PRODUCT_LINK_TO_WAREHOUSE":
      return (
        <>
          Utilisation de stock au produit{" "}
          <LinkUi to={`/products/${productId}`} component={Link}>
            {product?.name || productId}
          </LinkUi>{" "}
          pour l&apos;entrepôt{" "}
          <LinkUi to={`/warehouses/${warehouseId}`} component={Link}>
            {warehouse?.name || warehouseId}
          </LinkUi>
        </>
      );
    case "USE_STOCK_TO_PRODUCT_FOR_WAREHOUSE_LINK_BY_PRODUCT":
      return (
        <>
          Utilisation de stock au produit{" "}
          <LinkUi to={`/products/${productId}`} component={Link}>
            {product?.name || productId}
          </LinkUi>{" "}
          pour l&apos;entrepôt{" "}
          <LinkUi to={`/warehouses/${warehouseId}`} component={Link}>
            {warehouse?.name || warehouseId}
          </LinkUi>
        </>
      );
    case "USE_STOCK_TO_PRODUCT_FOR_STORAGE_PLACE_LINK_BY_PRODUCT":
      return (
        <>
          Utilisation de stock au produit{" "}
          <LinkUi to={`/products/${productId}`} component={Link}>
            {product?.name || productId}
          </LinkUi>{" "}
          pour le lieu de stockage{" "}
          <LinkUi to={`/storage-places/${storagePlaceId}`} component={Link}>
            {storagePlace?.name || storagePlaceId}
          </LinkUi>
        </>
      );
    case "OPEN_STOCK_TO_PRODUCT_FOR_PRODUCT_LINK_TO_STORAGE_PLACE":
      return (
        <>
          Ouverture de stock au produit{" "}
          <LinkUi to={`/products/${productId}`} component={Link}>
            {product?.name || productId}
          </LinkUi>{" "}
          pour le lieu de stockage{" "}
          <LinkUi to={`/storage-places/${storagePlaceId}`} component={Link}>
            {storagePlace?.name || storagePlaceId}
          </LinkUi>
        </>
      );
    case "OPEN_STOCK_TO_PRODUCT_FOR_PRODUCT_LINK_TO_WAREHOUSE":
      return (
        <>
          Ouverture de stock au produit{" "}
          <LinkUi to={`/products/${productId}`} component={Link}>
            {product?.name || productId}
          </LinkUi>{" "}
          pour l&apos;entrepôt{" "}
          <LinkUi to={`/warehouses/${warehouseId}`} component={Link}>
            {warehouse?.name || warehouseId}
          </LinkUi>
        </>
      );
    case "OPEN_STOCK_TO_PRODUCT_FOR_WAREHOUSE_LINK_BY_PRODUCT":
      return (
        <>
          Ouverture de stock au produit{" "}
          <LinkUi to={`/products/${productId}`} component={Link}>
            {product?.name || productId}
          </LinkUi>{" "}
          pour l&apos;entrepôt{" "}
          <LinkUi to={`/warehouses/${warehouseId}`} component={Link}>
            {warehouse?.name || warehouseId}
          </LinkUi>
        </>
      );
    case "OPEN_STOCK_TO_PRODUCT_FOR_STORAGE_PLACE_LINK_BY_PRODUCT":
      return (
        <>
          Ouverture de stock au produit{" "}
          <LinkUi to={`/products/${productId}`} component={Link}>
            {product?.name || productId}
          </LinkUi>{" "}
          pour le lieu de stockage{" "}
          <LinkUi to={`/storage-places/${storagePlaceId}`} component={Link}>
            {storagePlace?.name || storagePlaceId}
          </LinkUi>
        </>
      );
    case "CLOSE_STOCK_TO_PRODUCT_FOR_PRODUCT_LINK_TO_USE_PLACE":
      return (
        <>
          Fermeture de stock au produit{" "}
          <LinkUi to={`/products/${productId}`} component={Link}>
            {product?.name || productId}
          </LinkUi>{" "}
          pour le lieu d'utilisation{" "}
          <LinkUi to={`/use-places/${usePlaceId}`} component={Link}>
            {usePlace?.name || usePlaceId}
          </LinkUi>
        </>
      );
    case "CLOSE_STOCK_TO_PRODUCT_FOR_PRODUCT_LINK_TO_WAREHOUSE":
      return (
        <>
          Fermeture de stock au produit{" "}
          <LinkUi to={`/products/${productId}`} component={Link}>
            {product?.name || productId}
          </LinkUi>{" "}
          pour l&apos;entrepôt{" "}
          <LinkUi to={`/warehouses/${warehouseId}`} component={Link}>
            {warehouse?.name || warehouseId}
          </LinkUi>
        </>
      );
    case "CLOSE_STOCK_TO_PRODUCT_FOR_WAREHOUSE_LINK_BY_PRODUCT":
      return (
        <>
          Fermeture de stock au produit{" "}
          <LinkUi to={`/products/${productId}`} component={Link}>
            {product?.name || productId}
          </LinkUi>{" "}
          pour l&apos;entrepôt{" "}
          <LinkUi to={`/warehouses/${warehouseId}`} component={Link}>
            {warehouse?.name || warehouseId}
          </LinkUi>
        </>
      );
    case "CLOSE_STOCK_TO_PRODUCT_FOR_USE_PLACE_LINK_BY_PRODUCT":
      return (
        <>
          Fermeture de stock au produit{" "}
          <LinkUi to={`/products/${productId}`} component={Link}>
            {product?.name || productId}
          </LinkUi>{" "}
          pour le lieu d'utilisation{" "}
          <LinkUi to={`/use-places/${usePlaceId}`} component={Link}>
            {usePlace?.name || usePlaceId}
          </LinkUi>
        </>
      );
    case "CREATE_USE_PLACE":
      return (
        <>
          Création du lieu d'utilisation{" "}
          <LinkUi to={`/use-places/${usePlaceId}`} component={Link}>
            {usePlace?.name || usePlaceId}
          </LinkUi>
        </>
      );
    case "ADD_USE_PLACE_TO_WAREHOUSE":
      return (
        <>
          Ajout du lieu d'utilisation{" "}
          <LinkUi to={`/use-places/${usePlaceId}`} component={Link}>
            {usePlace?.name || usePlaceId}
          </LinkUi>{" "}
          à l&apos;entrepôt{" "}
          <LinkUi to={`/warehouses/${warehouseId}`} component={Link}>
            {warehouse?.name || warehouseId}
          </LinkUi>
        </>
      );
    case "ADD_WAREHOUSE_TO_USE_PLACE":
      return (
        <>
          Ajout de l&apos;entrepôt{" "}
          <LinkUi to={`/warehouses/${warehouseId}`} component={Link}>
            {warehouse?.name || warehouseId}
          </LinkUi>{" "}
          au lieu d'utilisation{" "}
          <LinkUi to={`/use-places/${usePlaceId}`} component={Link}>
            {usePlace?.name || usePlaceId}
          </LinkUi>
        </>
      );
    case "UPDATE_USE_PLACE":
      return (
        <>
          Mise à jour du lieu d'utilisation{" "}
          <LinkUi to={`/use-places/${usePlaceId}`} component={Link}>
            {usePlace?.name || usePlaceId}
          </LinkUi>
        </>
      );
    case "DELETE_USE_PLACE_TO_WAREHOUSE":
      return (
        <>
          Suppression du lieu d'utilisation{" "}
          <LinkUi to={`/use-places/${usePlaceId}`} component={Link}>
            {usePlace?.name || usePlaceId}
          </LinkUi>{" "}
          à l&apos;entrepôt{" "}
          <LinkUi to={`/warehouses/${warehouseId}`} component={Link}>
            {warehouse?.name || warehouseId}
          </LinkUi>
        </>
      );
    case "DELETE_WAREHOUSE_TO_USE_PLACE":
      return (
        <>
          Suppression de l&apos;entrepôt{" "}
          <LinkUi to={`/warehouses/${warehouseId}`} component={Link}>
            {warehouse?.name || warehouseId}
          </LinkUi>{" "}
          au lieu d'utilisation{" "}
          <LinkUi to={`/use-places/${usePlaceId}`} component={Link}>
            {usePlace?.name || usePlaceId}
          </LinkUi>
        </>
      );
    case "DELETE_USE_PLACE":
      return (
        <>
          Suppression du lieu d'utilisation{" "}
          <LinkUi to={`/use-places/${usePlaceId}`} component={Link}>
            {usePlace?.name || usePlaceId}
          </LinkUi>
        </>
      );
    case "ADD_USE_PLACE_TO_A_PRODUCT":
      return (
        <>
          Ajout du lieu d'utilisation{" "}
          <LinkUi to={`/use-places/${usePlaceId}`} component={Link}>
            {usePlace?.name || usePlaceId}
          </LinkUi>{" "}
          au produit{" "}
          <LinkUi to={`/products/${productId}`} component={Link}>
            {product?.name || productId}
          </LinkUi>
        </>
      );
    case "ADD_PRODUCT_TO_A_USE_PLACE":
      return (
        <>
          Ajout du produit{" "}
          <LinkUi to={`/products/${productId}`} component={Link}>
            {product?.name || productId}
          </LinkUi>{" "}
          au lieu d'utilisation{" "}
          <LinkUi to={`/use-places/${usePlaceId}`} component={Link}>
            {usePlace?.name || usePlaceId}
          </LinkUi>
        </>
      );
    default:
      return <>{actionType}</>;
  }
};

export { formatToWord };
