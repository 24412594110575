import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Grid, Button } from "@mui/material";
import MainLayout from "../../layouts/mainLayout/MainLayout";
import CreateOrUpdateStoragePlace from "components/StoragePlaces/CreateOrUpdateStoragePlace";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import SnackbarCloseIcon from "../../components/Snackbar/SnackbarCloseIcon";
import StoragePlaceProducts from "components/StoragePlaces/StoragePlaceProducts";
import ResourceHistory from "../../components/ResourceHistory/ResourceHistory";
import StoragePlaceLinkedToProductsModal from "components/StoragePlaces/StoragePlaceLinkedToProductsModal";
import { useProducts } from "../../Hooks/products.hook";
import { useWarehouses } from "../../Hooks/warehouses.hook";
import {
  useDeleteStoragePlace,
  useStoragePlace,
  useStoragePlacesHistory,
} from "../../Hooks/storage-places.hook";
import { AxiosError, AxiosResponse } from "axios";
import { IStoragePlace } from "src/types/storage-place.type";

const StoragePlaceDetail = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { storagePlaceId = "" } = useParams<{
    storagePlaceId: IStoragePlace["id"];
  }>();
  const navigate = useNavigate();

  const [showConfirmDeleteStoragePlace, setShowConfirmDeleteStoragePlace] =
    useState(false);
  const [productsLinkeds, setProductsLinkeds] = useState([]);

  const {
    products,
    isFetching: isFetchingProducts,
    isError: isErrorProducts,
  } = useProducts({
    join: "storagePlaces",
    filter: `storagePlaces.id||eq||${storagePlaceId}`,
  });

  const {
    warehouses,
    isFetching: isFetchingWarehouses,
    isError: isErrorWarehouses,
  } = useWarehouses({});

  const {
    storagePlace,
    isFetching: isFetchingStoragePlace,
    isError: isErrorStoragePlace,
    error: errorStoragePlace,
  } = useStoragePlace({
    storagePlaceId,
  });

  const { mutate: deleteStoragePlace, isPending: isPendingDeleteStoragePlace } =
    useDeleteStoragePlace({
      onSuccess: () => {
        enqueueSnackbar(
          `Le lieu de stockage "${storagePlace.name}" a été supprimé.`,
          {
            variant: "success",
            action: (snackbarKey) => (
              <SnackbarCloseIcon snackbarKey={snackbarKey} />
            ),
          },
        );
        navigate("/storage-places");
      },
      onError: ({ response }) => {
        const { data } = response as AxiosResponse;

        const storagePlaceHaveProductsLinkeds =
          data &&
          data.title &&
          data.title.error &&
          data.title.message &&
          data.title.productsLinkeds &&
          data.title.error === "STORAGE_PLACE_HAVE_PRODUCTS_LINKEDS";
        if (storagePlaceHaveProductsLinkeds) {
          setShowConfirmDeleteStoragePlace(false);
          setProductsLinkeds(data.title.productsLinkeds);
        } else {
          enqueueSnackbar(
            `Une erreur est survenue lors de la suppression du lieu de stockage "${storagePlace.name}".`,
            {
              variant: "error",
              action: (snackbarKey) => (
                <SnackbarCloseIcon snackbarKey={snackbarKey} />
              ),
            },
          );
        }
      },
    });

  const {
    storagePlacesHistory,
    isFetching: isFetchingStoragePlacesHistory,
    isError: isErrorStoragePlacesHistory,
    refetch: refetchStoragePlacesHistory,
  } = useStoragePlacesHistory({
    storagePlaceId,
  });

  useEffect(() => {
    if (isErrorProducts) {
      enqueueSnackbar(
        "Une erreur est survenue lors de la récupération des produits liés au lieu de stockage.",
        {
          variant: "error",
          action: (key) => <SnackbarCloseIcon snackbarKey={key} />,
        },
      );
    }
  }, [enqueueSnackbar, isErrorProducts]);

  useEffect(() => {
    if (isErrorWarehouses) {
      enqueueSnackbar(
        "Une erreur est survenue lors de la récupération des entrepôts. Impossible de modifier le lieu de stockage.",
        {
          variant: "error",
          action: (snackbarKey) => (
            <SnackbarCloseIcon snackbarKey={snackbarKey} />
          ),
        },
      );
      navigate("/storage-places");
    }
  }, [enqueueSnackbar, isErrorWarehouses, navigate]);

  useEffect(() => {
    if (isErrorStoragePlace) {
      const storagePlaceNotFound =
        (errorStoragePlace as AxiosError)?.response?.status === 404;

      enqueueSnackbar(
        storagePlaceNotFound
          ? `Le lieu de stockage "${storagePlaceId}" est introuvable.`
          : "Une erreur est survenue lors de la récupération du lieu de stockage.",
        {
          variant: storagePlaceNotFound ? "warning" : "error",
          action: (key) => <SnackbarCloseIcon snackbarKey={key} />,
        },
      );
      navigate("/storage-places");
    }
  }, [enqueueSnackbar, isErrorStoragePlace, navigate, storagePlaceId]);

  useEffect(() => {
    if (isErrorStoragePlacesHistory) {
      enqueueSnackbar(
        "Une erreur est survenue lors de la récupération de l'historique du lieu de stockage.",
        {
          variant: "error",
          action: (key) => <SnackbarCloseIcon snackbarKey={key} />,
        },
      );
    }
  }, [isErrorStoragePlacesHistory, enqueueSnackbar]);

  const handleChangeConfirmDeleteStoragePlace = () => {
    if (!isPendingDeleteStoragePlace) {
      setShowConfirmDeleteStoragePlace(!showConfirmDeleteStoragePlace);
    }
  };

  return (
    <MainLayout>
      {productsLinkeds.length > 0 && (
        <StoragePlaceLinkedToProductsModal
          title={`Il n'est pas possible de supprimer le lieu de stockage "${storagePlace.name}".`}
          productsLinkeds={productsLinkeds}
          storagePlace={storagePlace}
          handleClose={() => setProductsLinkeds([])}
          transitionDurationEnter={0}
        />
      )}
      {showConfirmDeleteStoragePlace && (
        <ConfirmationModal
          key="delete-place-confirmation-modal"
          open
          loadingSuccessCallback={isPendingDeleteStoragePlace}
          title="Suppression d&#39;un lieu de stockage."
          description={`Voulez-vous vraiment supprimer le lieu de stockage "${storagePlace.name}" ?`}
          handleClose={handleChangeConfirmDeleteStoragePlace}
          yesCallback={() => deleteStoragePlace({ storagePlaceId })}
        />
      )}
      <Grid container spacing={4}>
        <Grid item xs={12} container justifyContent="flex-end">
          <Button
            onClick={handleChangeConfirmDeleteStoragePlace}
            variant="contained"
            color="secondary"
            disabled={isFetchingStoragePlace || isFetchingWarehouses}
          >
            Supprimer
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item xs={12} lg={6} xl={4}>
              <CreateOrUpdateStoragePlace
                storagePlace={storagePlace}
                title={
                  storagePlace && storagePlace.name
                    ? `Mise à jour de "${storagePlace.name}"`
                    : ""
                }
                callback={() => refetchStoragePlacesHistory()}
                loading={isFetchingStoragePlace || isFetchingWarehouses}
                warehouses={warehouses}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <StoragePlaceProducts
            loading={isFetchingProducts}
            products={products}
          />
        </Grid>
        <Grid item xs={12}>
          <ResourceHistory
            history={storagePlacesHistory}
            loading={isFetchingStoragePlacesHistory}
          />
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default StoragePlaceDetail;
