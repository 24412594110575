import { useState, useEffect } from "react";
import Resizer from "react-image-file-resizer";
import PageLoader from "../Loaders/PageLoader";
import { useStoragePlacePicture } from "Hooks/storage-places.hook";
import { TImage } from "types/image.type";
import { IStoragePlace } from "src/types/storage-place.type";

const Photo = require("../../public/img/imgnotfound.png");

interface IStoragePlaceImageProps {
  storagePlaceId: IStoragePlace["id"];
  rootStyle: string;
}

const StoragePlaceImage = (props: IStoragePlaceImageProps) => {
  const { storagePlaceId, rootStyle } = props;

  const [image, setImage] = useState<TImage>();

  const {
    storagePlacePicture,
    isFetching: isLoadingStoragePlacePicture,
    isError: isErrorStoragePlacePicture,
  } = useStoragePlacePicture({
    storagePlaceId,
  });

  useEffect(() => {
    if (!isLoadingStoragePlacePicture) {
      if (isErrorStoragePlacePicture && !storagePlacePicture) {
        setImage(Photo);
      } else {
        Resizer.imageFileResizer(
          storagePlacePicture,
          250,
          500,
          "PNG",
          100,
          0,
          (picture) => {
            setImage(picture);
          },
          "base64",
        );
      }
    }
  }, [
    isLoadingStoragePlacePicture,
    isErrorStoragePlacePicture,
    storagePlacePicture,
  ]);

  return isLoadingStoragePlacePicture ||
    (!isLoadingStoragePlacePicture && !image) ? (
    <PageLoader className={rootStyle} />
  ) : (
    <img
      className={rootStyle}
      alt={image?.toString()}
      src={image?.toString()}
    />
  );
};

export default StoragePlaceImage;
