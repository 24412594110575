import { useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { makeStyles } from "tss-react/mui";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Autocomplete from "@mui/lab/Autocomplete";
import isUuid from "is-uuid";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import PageLoader from "../Loaders/PageLoader";
import { useAddProductToAStoragePlace } from "Hooks/products.hook";
import SnackbarCloseIcon from "../Snackbar/SnackbarCloseIcon";
import { Theme } from "@mui/material";
import { IProduct } from "types/product.type";
import { IWarehouse } from "types/warehouse.type";
import { AxiosResponse } from "axios";
import { IStoragePlace } from "src/types/storage-place.type";

const useStyles = makeStyles()((theme: Theme) => ({
  paper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2, 3, 3),
  },
  submit: {
    float: "right",
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  form: {
    width: "100%",
  },
  noWarehouseOrStoragePlace: {
    margin: "auto",
    textAlign: "center",
  },
}));

interface IProductAddStoragePlaceProps {
  loading?: boolean;
  product: IProduct;
  warehouses: Array<IWarehouse>;
}

const ProductAddStoragePlace = (props: IProductAddStoragePlaceProps) => {
  const { loading = false, product, warehouses } = props;

  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  interface IInitialValues {
    warehouseId: string;
    storagePlaceId: string;
    storagePlaceName?: IStoragePlace["name"];
  }
  const initialValues: IInitialValues = {
    warehouseId: "",
    storagePlaceId: "",
  };

  const [storagePlaceName, setStoragePlaceName] = useState<
    IStoragePlace["name"] | null
  >(null);
  const [resetForm, setResetForm] = useState<() => void>(() => {});

  const {
    mutate: addProductToAStoragePlace,
    isPending: isPendingAddProductToAStoragePlace,
  } = useAddProductToAStoragePlace({
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ["products-history", { productId: product.id }],
        exact: true,
      });

      queryClient.refetchQueries({
        queryKey: ["product", { productId: product.id }],
        exact: true,
      });

      resetForm();
    },
    onError: ({ response }) => {
      const { data } = response as AxiosResponse;

      const productAlreadyLinkedError =
        data &&
        data.title &&
        data.title === "PRODUCT_ALREADY_LINKED_TO_STORAGE_PLACE";
      enqueueSnackbar(
        `${
          productAlreadyLinkedError
            ? `Le produit "${product.name}" est déjà lié au lieu de stockage "${storagePlaceName}".`
            : `Une erreur est survenue lors de l'ajout de produit "${product.name}" au lieu de stockage "${storagePlaceName}". Veuillez réessayer.`
        }`,
        {
          variant: productAlreadyLinkedError ? "warning" : "error",
          action: (snackbarKey) => (
            <SnackbarCloseIcon snackbarKey={snackbarKey} />
          ),
        },
      );
    },
  });

  if (!loading && warehouses.length === 0) {
    return (
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h5">
          Ajouter à un lieu de stockage
        </Typography>
        <div className={classes.noWarehouseOrStoragePlace}>
          <div>
            Aucun {warehouses.length === 0 ? "entrepôt" : "lieu de stockage"}
          </div>
          <div>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() =>
                navigate(
                  warehouses.length === 0
                    ? "/new-warehouse"
                    : "/new-storage-place",
                )
              }
            >
              Ajouter un{" "}
              {warehouses.length === 0 ? "entrepôt" : "lieu de stockage"}
            </Button>
          </div>
        </div>
      </Paper>
    );
  }

  return (
    <Paper className={classes.paper}>
      {loading ? (
        <PageLoader />
      ) : (
        <>
          <Typography component="h1" variant="h5">
            Ajouter à un lieu de stockage
          </Typography>
          <Formik
            initialValues={initialValues}
            onSubmit={(
              { storagePlaceId, storagePlaceName: newStoragePlaceName },
              { resetForm: newResetForm },
            ) => {
              setStoragePlaceName(newStoragePlaceName || null);
              setResetForm(newResetForm);
              addProductToAStoragePlace({
                productId: product.id,
                storagePlaceId,
              });
            }}
            validationSchema={yup.object().shape({
              warehouseId: yup
                .mixed()
                .test(
                  "wrongWarehouseId",
                  "L'entrepôt est requis.",
                  (warehouseId) =>
                    isUuid.anyNonNil(warehouseId?.toString() || ""),
                ),
              storagePlaceId: yup
                .mixed()
                .test(
                  "wrongWarehouseId",
                  "Le lieu de stockage est requis.",
                  (storagePlaceId) =>
                    isUuid.anyNonNil(storagePlaceId?.toString() || ""),
                ),
            })}
            render={({
              values,
              touched,
              errors,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit} className={classes.form} noValidate>
                <Autocomplete
                  onChange={(_, value) => {
                    setFieldValue("warehouseId", value ? value.id : "");
                    setFieldValue("storagePlaceId", "");
                  }}
                  value={
                    warehouses[
                      warehouses.findIndex(
                        (warehouse) => warehouse.id === values.warehouseId,
                      )
                    ] || null
                  }
                  options={warehouses}
                  getOptionLabel={(option) => option.name}
                  disableClearable
                  noOptionsText="Aucun entrepôt"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Entrepôt"
                      fullWidth
                      className={classes.textField}
                      helperText={
                        errors.warehouseId &&
                        touched.warehouseId &&
                        errors.warehouseId
                      }
                      error={!!errors.warehouseId && touched.warehouseId}
                    />
                  )}
                />

                {values.warehouseId && (
                  <Autocomplete
                    onChange={(_, value) => {
                      setFieldValue("storagePlaceId", value?.id || "");
                      setFieldValue("storagePlaceName", value?.name || null);
                    }}
                    value={
                      warehouses
                        .find(
                          (warehouse) => warehouse.id === values.warehouseId,
                        )
                        ?.storagePlaces?.find(
                          (storagePlace: IStoragePlace) =>
                            storagePlace.id === values.storagePlaceId,
                        ) || undefined
                    }
                    options={
                      warehouses.find(
                        (warehouse) => warehouse.id === values.warehouseId,
                      )?.storagePlaces || []
                    }
                    getOptionLabel={(option) => option.name}
                    disableClearable
                    noOptionsText="Aucun lieu de stockage"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Lieu de stockage"
                        fullWidth
                        className={classes.textField}
                        helperText={
                          errors.storagePlaceId &&
                          touched.storagePlaceId &&
                          errors.storagePlaceId
                        }
                        error={
                          !!errors.storagePlaceId && touched.storagePlaceId
                        }
                      />
                    )}
                  />
                )}

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={isPendingAddProductToAStoragePlace}
                >
                  {isPendingAddProductToAStoragePlace && (
                    <CircularProgress color="inherit" size={14} />
                  )}
                  {!isPendingAddProductToAStoragePlace &&
                    "Lier le produit à l'entrepôt"}
                </Button>
              </form>
            )}
          />
        </>
      )}
    </Paper>
  );
};

export default ProductAddStoragePlace;
