import Keycloak from "keycloak-js";

const keycloak = new Keycloak("/keycloak.json");

let initPromise: ReturnType<typeof keycloak.init> | null = null;

export const initKeycloak = (): ReturnType<typeof keycloak.init> => {
  if (!initPromise) {
    initPromise = keycloak.init({
      onLoad: "login-required",
      checkLoginIframe: false,
      pkceMethod: "S256",
    });
  }
  return initPromise;
};

export const doLogin = keycloak.login;

export const updateToken = (): ReturnType<typeof keycloak.updateToken> =>
  keycloak.updateToken();

export const getToken = () => keycloak.token;
