import { useState } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/lab/Autocomplete";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { makeStyles } from "tss-react/mui";
import { useQueryClient } from "@tanstack/react-query";
import PageLoader from "../Loaders/PageLoader";
import SnackbarCloseIcon from "../Snackbar/SnackbarCloseIcon";
import StoragePlaceImage from "./StoragePlaceImage";
import {
  useCreateStoragePlace,
  useEditStoragePlace,
} from "../../Hooks/storage-places.hook";
import { IWarehouse } from "types/warehouse.type";
import { IProduct } from "types/product.type";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { IStoragePlace } from "src/types/storage-place.type";
import StoragePlaceLinkedToProductsModal from "./StoragePlaceLinkedToProductsModal";

const useStyles = makeStyles()((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2, 3, 3),
  },
  submit: {
    float: "right",
    marginTop: theme.spacing(8),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  imageWrapper: {
    display: "flex",
    height: "250px",
  },
  image: {
    margin: "auto",
    maxHeight: "100%",
    maxWidth: "100%",
  },
  popupIndicator: {
    display: "none",
  },
  noWarehouse: {
    margin: "auto",
    textAlign: "center",
  },
}));

interface ICreateOrUpdateStoragePlaceProps {
  loading?: boolean;
  storagePlace?: IStoragePlace | null;
  callback?: () => void;
  title: string;
  warehouses: Array<IWarehouse>;
}

const CreateOrUpdateStoragePlace = (
  props: ICreateOrUpdateStoragePlaceProps,
) => {
  const {
    loading = false,
    storagePlace = null,
    callback = null,
    title,
    warehouses,
  } = props;

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { classes } = useStyles();

  const [productsLinkeds, setProductsLinkeds] = useState<Array<IProduct>>([]);

  const { mutate: createStoragePlace, isPending: isPendingCreateStoragePlace } =
    useCreateStoragePlace({
      onSuccess: ({ data }) => {
        enqueueSnackbar(`Le lieu de stockage "${data.name}" a été ajouté.`, {
          variant: "success",
          action: (snackbarKey) => (
            <SnackbarCloseIcon snackbarKey={snackbarKey} />
          ),
        });
        navigate(`/storage-places/${data.id}`);
      },
      onError: ({ config }) => {
        const { data } = config as AxiosRequestConfig;

        enqueueSnackbar(
          `Une erreur est survenue lors de l'ajout du lieu de stockage "${data.get("name")}".`,
          {
            variant: "error",
            action: (snackbarKey) => (
              <SnackbarCloseIcon snackbarKey={snackbarKey} />
            ),
          },
        );
      },
    });

  const { mutate: editStoragePlace, isPending: isPendingEditStoragePlace } =
    useEditStoragePlace({
      onSuccess: ({ data }) => {
        enqueueSnackbar(
          `Le lieu de stockage "${storagePlace?.name || ""}" a été mis à jour.`,
          {
            variant: "success",
            action: (snackbarKey) => (
              <SnackbarCloseIcon snackbarKey={snackbarKey} />
            ),
          },
        );

        if (storagePlace?.pictureFileName !== data.pictureFileName) {
          queryClient.invalidateQueries({
            queryKey: [
              "storage-place-picture",
              { storagePlaceId: storagePlace?.id },
            ],
            exact: true,
          });
        }

        queryClient.refetchQueries({
          queryKey: ["storage-place", { storagePlaceId: storagePlace?.id }],
          exact: true,
        });

        if (callback) {
          callback();
        }
      },
      onError: ({ response }) => {
        const { data } = response as AxiosResponse;

        const storagePlaceHaveProductsLinkeds =
          data &&
          data.title &&
          data.title.error &&
          data.title.message &&
          data.title.productsLinkeds &&
          data.title.error === "STORAGE_PLACE_HAVE_PRODUCTS_LINKEDS";

        if (storagePlaceHaveProductsLinkeds) {
          setProductsLinkeds(data?.title?.productsLinkeds);
        } else {
          enqueueSnackbar(
            `Une erreur est survenue lors de la mise à jour du lieu de stockage "${storagePlace?.name || ""}".`,
            {
              variant: "error",
              action: (snackbarKey) => (
                <SnackbarCloseIcon snackbarKey={snackbarKey} />
              ),
            },
          );
        }
      },
    });

  interface IInitialValues {
    storagePlaceName: string;
    warehouseId: string;
    storagePlacePicture?: string;
  }

  const initialValues: IInitialValues = {
    storagePlaceName: storagePlace?.name || "",
    storagePlacePicture: undefined,
    warehouseId: storagePlace?.warehouseId?.toString() || "",
  };

  if (!loading && (!warehouses || warehouses.length === 0)) {
    return (
      <Paper className={classes.paper}>
        <div className={classes.noWarehouse}>
          <div>Impossible de récupérer les entrepôts.</div>
          <div>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate("/storage-places")}
            >
              Retourner au listing des lieux de stockage.
            </Button>
          </div>
        </div>
      </Paper>
    );
  }

  return (
    <Paper className={classes.paper}>
      {loading ? (
        <PageLoader />
      ) : (
        <>
          {productsLinkeds.length > 0 && (
            <StoragePlaceLinkedToProductsModal
              title={`Il n'est pas possible de changer d'entrepôt le lieu de stockage "${storagePlace?.name || ""}".`}
              productsLinkeds={productsLinkeds}
              storagePlace={storagePlace || undefined}
              handleClose={() => setProductsLinkeds([])}
            />
          )}
          <Typography component="h1" variant="h5">
            {title}
          </Typography>
          {storagePlace && (
            <div className={classes.imageWrapper}>
              <StoragePlaceImage
                rootStyle={classes.image}
                storagePlaceId={storagePlace.id}
              />
            </div>
          )}
          <Formik
            initialValues={initialValues}
            onSubmit={({
              storagePlaceName,
              warehouseId,
              storagePlacePicture,
            }: IInitialValues) => {
              const formData = new FormData();
              formData.append("name", storagePlaceName);
              formData.append("warehouseId", warehouseId);
              if (storagePlacePicture) {
                formData.append("picture", storagePlacePicture);
              }

              if (!storagePlace) {
                createStoragePlace({ storagePlace: formData });
              } else {
                editStoragePlace({
                  storagePlaceId: storagePlace.id,
                  storagePlace: formData,
                });
              }
            }}
            validationSchema={yup.object().shape({
              storagePlaceName: yup
                .string()
                .required(`Le nom du lieu de stockage est requis.`),
              storagePlacePicture: yup
                .mixed<File>()
                .test(
                  "fileFormat",
                  "Le format de l'image n'est pas valide.",
                  (value) => value?.type?.startsWith("image/") || true,
                ),
              warehouseId: yup
                .string()
                .required(
                  `L'entrepôt dans lequel se trouve le lieu de stockage est requis.`,
                )
                .test(
                  "warehouseId",
                  "L'entrepôt dans lequel se trouve le lieu de stockage n'est pas valide.",
                  (value) =>
                    !!warehouses.find((warehouse) => warehouse?.id === value),
                ),
            })}
            render={({
              values,
              touched,
              errors,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit} noValidate>
                <TextField
                  label="Nom du lieu de stockage"
                  type="text"
                  name="storagePlaceName"
                  autoFocus
                  value={values.storagePlaceName}
                  onChange={handleChange}
                  fullWidth
                  className={classes.textField}
                  helperText={
                    errors.storagePlaceName &&
                    touched.storagePlaceName &&
                    errors.storagePlaceName
                  }
                  error={
                    !!errors?.storagePlaceName && touched?.storagePlaceName
                  }
                />

                <Autocomplete
                  onChange={(_, value) => {
                    setFieldValue("warehouseId", value ? value.id : "");
                  }}
                  value={
                    warehouses[
                      warehouses.findIndex(
                        (warehouse) =>
                          warehouse?.id.toString() === values.warehouseId,
                      )
                    ]
                  }
                  options={warehouses}
                  getOptionLabel={(option) => option.name}
                  disableClearable
                  classes={{ popupIndicator: classes.popupIndicator }}
                  noOptionsText="Aucun entrepôt"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Entrepôt"
                      fullWidth
                      className={classes.textField}
                      helperText={
                        errors.warehouseId &&
                        touched.warehouseId &&
                        errors.warehouseId
                      }
                      error={!!errors?.warehouseId && touched?.warehouseId}
                    />
                  )}
                />

                <TextField
                  label={storagePlace ? "Mettre à jour l'image" : "Image"}
                  inputProps={{ accept: "image/*" }}
                  InputLabelProps={{ shrink: true }}
                  type="file"
                  name="storagePlacePicture"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const { files } = event.currentTarget;

                    setFieldValue(
                      "storagePlacePicture",
                      files && files[0] ? files[0] : undefined,
                    );
                  }}
                  fullWidth
                  className={classes.textField}
                  helperText={
                    errors.storagePlacePicture &&
                    touched.storagePlacePicture &&
                    errors.storagePlacePicture
                  }
                  error={
                    !!errors?.storagePlacePicture &&
                    !!touched.storagePlacePicture
                  }
                />

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={
                    isPendingEditStoragePlace || isPendingCreateStoragePlace
                  }
                >
                  {(isPendingEditStoragePlace ||
                    isPendingCreateStoragePlace) && (
                    <CircularProgress color="inherit" size={14} />
                  )}
                  {(!isPendingEditStoragePlace ||
                    !isPendingCreateStoragePlace) &&
                    (storagePlace ? "Mettre à jour" : "Ajouter")}
                </Button>
              </form>
            )}
          />
        </>
      )}
    </Paper>
  );
};

export default CreateOrUpdateStoragePlace;
