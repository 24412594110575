import axios from "axios";
import config from "../config";
import { TFilter, TJoin } from "types/query.type";
import { IWarehouse } from "types/warehouse.type";

export const getWarehouses = (join: TJoin, filter: TFilter) =>
  axios.get(`${config.API.HOST}/warehouses`, {
    params: {
      join,
      filter,
    },
  });

export const postWarehouse = (warehouse: FormData) =>
  axios.post(`${config.API.HOST}/warehouses`, warehouse);

export const patchWarehouse = (
  warehouseId: IWarehouse["id"],
  warehouseFormData: FormData,
) =>
  axios.patch(
    `${config.API.HOST}/warehouses/${warehouseId}`,
    warehouseFormData,
  );

export const getWarehouse = (warehouseId: IWarehouse["id"]) =>
  axios.get(`${config.API.HOST}/warehouses/${warehouseId}`);

export const getWarehousePicture = (warehouseId: IWarehouse["id"]) =>
  axios.get(`${config.API.HOST}/warehouses/${warehouseId}/picture`, {
    responseType: "blob",
  });

export const deleteWarehouse = (warehouseId: IWarehouse["id"]) =>
  axios.delete(`${config.API.HOST}/warehouses/${warehouseId}`);

export const getWarehousesHistory = (warehouseId: IWarehouse["id"]) =>
  axios.get(`${config.API.HOST}/actionshistory`, {
    params: {
      join: "warehouse,product||true,storagePlace,usePlace",
      filter: [`warehouseId||eq||${warehouseId}`, "sourceType||eq||WAREHOUSE"],
      sort: "createdAt,ASC",
    },
  });
