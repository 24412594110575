import { StrictMode } from "react";
import Axios, { AxiosRequestConfig } from "axios";
import ReactDOM from "react-dom/client";
import "index.css";
import App from "App";
import { doLogin, getToken, updateToken } from "services/KeycloakService";

// Authent interceptor
Axios.interceptors.request.use(
  (config: AxiosRequestConfig): any => {
    const callbackFunc = () => {
      const configWithAuthorization = {
        ...config,
        headers: {
          ...{ Authorization: `Bearer ${getToken()}` },
          ...config.headers,
        },
      };
      return Promise.resolve(configWithAuthorization);
    };

    return updateToken()
      .then(() => callbackFunc())
      .catch(() => doLogin());
  },
  (error) => Promise.reject(error),
);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <StrictMode>
    <App />
  </StrictMode>,
);
