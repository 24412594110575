import { useState, useEffect } from "react";
import Resizer from "react-image-file-resizer";
import PageLoader from "../Loaders/PageLoader";
import { useUsePlacePicture } from "Hooks/use-places.hook";
import { TImage } from "types/image.type";
import { IUsePlace } from "types/use-place.type";

const Photo = require("../../public/img/imgnotfound.png");

interface IUsePlaceImageProps {
  usePlaceId: IUsePlace["id"];
  rootStyle: string;
}

const UsePlaceImage = (props: IUsePlaceImageProps) => {
  const { usePlaceId, rootStyle } = props;

  const [image, setImage] = useState<TImage>();

  const {
    usePlacePicture,
    isFetching: isLoadingUsePlacePicture,
    isError: isErrorUsePlacePicture,
  } = useUsePlacePicture({
    usePlaceId,
  });

  useEffect(() => {
    if (!isLoadingUsePlacePicture) {
      if (isErrorUsePlacePicture && !usePlacePicture) {
        setImage(Photo);
      } else {
        Resizer.imageFileResizer(
          usePlacePicture,
          250,
          500,
          "PNG",
          100,
          0,
          (picture) => {
            setImage(picture);
          },
          "base64",
        );
      }
    }
  }, [isLoadingUsePlacePicture, isErrorUsePlacePicture, usePlacePicture]);

  return isLoadingUsePlacePicture || (!isLoadingUsePlacePicture && !image) ? (
    <PageLoader className={rootStyle} />
  ) : (
    <img
      className={rootStyle}
      alt={image?.toString()}
      src={image?.toString()}
    />
  );
};

export default UsePlaceImage;
