import { useState } from "react";
import { blue, red } from "@mui/material/colors";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import moment from "moment";
import "moment/locale/fr";
import { makeStyles } from "tss-react/mui";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Router from "Router";

moment.locale("fr");

const defaultTheme = createTheme({
  palette: {
    primary: red,
    secondary: blue,
    // border: "#DFE3E8",
  },
  typography: {
    fontFamily: "Courier Helvetica",
  },
  components: {
    MuiBadge: {
      styleOverrides: {
        badge: {
          minHeight: 30,
          minWidth: 30,
          borderRadius: 15,
          fontSize: "1rem",
        },
      },
    },
  },
});

const useStyles = makeStyles()((theme) => ({
  snackbarRoot: {
    [theme.breakpoints.up("md")]: {
      width: "40%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "30%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "20%",
    },
  },
  snackbarBase: {
    width: "100%",
    flexFlow: "row",
  },
}));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 10000,
      retry: (failureCount, error: any) => {
        if (failureCount >= 1 || error?.response?.status === 404) {
          return false;
        }
        return true;
      },
    },
  },
});

const App = () => {
  const [theme] = useState(defaultTheme);
  const { classes } = useStyles();

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          classes={{
            root: classes.snackbarRoot,
            containerRoot: classes.snackbarBase,
          }}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Router />
        </SnackbarProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
