import { Link } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import LinkUi from "@mui/material/Link";
import Divider from "@mui/material/Divider";
import PageLoader from "../Loaders/PageLoader";
import StoragePlaceImage from "../StoragePlaces/StoragePlaceImage";
import { Theme } from "@mui/material";
import { IStoragePlace } from "src/types/storage-place.type";

const useStyles = makeStyles()((theme: Theme) => ({
  paper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2, 3, 3),
  },
  noLinkeds: {
    margin: "auto",
    textAlign: "center",
  },
  storagePlacesToPlaceContainer: {
    wordBreak: "break-word",
    padding: theme.spacing(1, 1),
    textAlign: "right",
  },
  storagePlaceImageWrapper: {
    height: "250px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  storagePlaceImage: {
    maxHeight: "100%",
    maxWidth: "100%",
  },
  storagePlaceFooterContainer: {
    wordBreak: "break-word",
    padding: theme.spacing(1, 1),
    textAlign: "right",
  },
  linkGoToStoragePlaceDetail: {
    "&:hover": {
      background: theme.palette.primary.dark,
    },
  },
  storagePlaceName: {
    fontSize: "18px",
    textAlign: "center",
    color: theme.palette.text.secondary,
    wordBreak: "break-all",
  },
}));

const Title = () => {
  return (
    <Typography component="h1" variant="h5">
      Lieux de stockage
    </Typography>
  );
};

interface IWarehouseStoragePlacesProps {
  loading?: boolean;
  storagePlaces: Array<IStoragePlace>;
}

const WarehouseStoragePlaces = (props: IWarehouseStoragePlacesProps) => {
  const { loading = false, storagePlaces } = props;

  const { classes } = useStyles();

  if (!loading && storagePlaces.length === 0) {
    return (
      <Paper className={classes.paper}>
        <Title />
        <div className={classes.noLinkeds}>
          <div>Aucun lieu de stockage lié à l&apos;entrepôt.</div>
        </div>
      </Paper>
    );
  }

  return (
    <Paper className={classes.paper}>
      {loading ? (
        <PageLoader />
      ) : (
        <>
          <Title />
          <Grid
            className={classes.storagePlacesToPlaceContainer}
            container
            spacing={4}
          >
            {storagePlaces.map((storagePlace) => (
              <Grid item key={storagePlace.id} lg={4} md={6} xs={12}>
                <Paper elevation={0}>
                  <LinkUi
                    to={`/storage-places/${storagePlace.id}`}
                    component={Link}
                    underline="none"
                  >
                    <div className={classes.linkGoToStoragePlaceDetail}>
                      <div className={classes.storagePlaceImageWrapper}>
                        <StoragePlaceImage
                          rootStyle={classes.storagePlaceImage}
                          storagePlaceId={storagePlace.id}
                        />
                      </div>
                      <Typography
                        className={classes.storagePlaceName}
                        variant="h4"
                      >
                        {storagePlace.name}
                      </Typography>
                    </div>
                  </LinkUi>
                  <Divider />
                  <Grid
                    className={classes.storagePlaceFooterContainer}
                    container
                    justifyContent="space-between"
                  >
                    AUCUNE ACTION POSSIBLE ACTUELLEMENT
                  </Grid>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Paper>
  );
};

export default WarehouseStoragePlaces;
