import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { Grid } from "@mui/material";
import MainLayout from "../../layouts/mainLayout/MainLayout";
import CreateOrUpdateStoragePlace from "components/StoragePlaces/CreateOrUpdateStoragePlace";
import SnackbarCloseIcon from "../../components/Snackbar/SnackbarCloseIcon";
import { useWarehouses } from "../../Hooks/warehouses.hook";

const CreateStoragePlace = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { warehouses, isFetching, isError } = useWarehouses({});

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(
        "Une erreur est survenue lors de la récupération des entrepôts.",
        {
          variant: "error",
          action: (snackbarKey) => (
            <SnackbarCloseIcon snackbarKey={snackbarKey} />
          ),
        },
      );
    }
  }, [enqueueSnackbar, isError]);

  return (
    <MainLayout>
      <Grid container spacing={16} justifyContent="center">
        <Grid item xs={12} sm={11} md={8} lg={6} xl={4}>
          <CreateOrUpdateStoragePlace
            title="Ajouter un lieu de stockage"
            warehouses={warehouses}
            loading={isFetching}
          />
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default CreateStoragePlace;
