import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LinkUi from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import Skeleton from "react-loading-skeleton";
import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { useStoragePlacesLinkedToWarehouse } from "../../Hooks/storage-places.hook";
import SnackbarCloseIcon from "../Snackbar/SnackbarCloseIcon";
import { IWarehouse } from "types/warehouse.type";
import { IStoragePlace } from "src/types/storage-place.type";

interface IWarehouseStoragePlacesNamesProps {
  warehouse: IWarehouse;
}

const WarehouseStoragePlacesNames = (
  props: IWarehouseStoragePlacesNamesProps,
) => {
  const { warehouse } = props;

  const { enqueueSnackbar } = useSnackbar();

  const { storagePlaces, isFetching, isError } =
    useStoragePlacesLinkedToWarehouse({
      warehouseId: warehouse.id,
    });

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(
        `Une erreur est survenue lors de la récupération des lieux de stockage liés à l'entrepôt "${warehouse.name}".`,
        {
          variant: "error",
          action: (snackbarKey) => (
            <SnackbarCloseIcon snackbarKey={snackbarKey} />
          ),
        },
      );
    }
  }, [isError, enqueueSnackbar, warehouse.name]);

  const [anchorEl, setAnchorEl] = useState<
    React.SyntheticEvent["currentTarget"] | null
  >(null);

  if (isFetching) {
    return (
      <Box
        sx={{
          width: "50%",
          fontSize: (theme) => theme.typography.fontSize,
        }}
      >
        <Skeleton />
      </Box>
    );
  }

  if (storagePlaces.length === 1) {
    return (
      <Typography>
        <LinkUi component={Link} to={`/storage-places/${storagePlaces[0].id}`}>
          {storagePlaces[0].name}
        </LinkUi>
      </Typography>
    );
  }

  if (storagePlaces.length > 1) {
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return [
      <Typography key="storage-place-open-popover">
        <LinkUi
          sx={{ cursor: "pointer" }}
          onClick={(event: React.SyntheticEvent) => {
            setAnchorEl(event.currentTarget);
          }}
        >{`${storagePlaces.length} lieux de stockage`}</LinkUi>
      </Typography>,
      <Popover
        key="storage-place-popover"
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {storagePlaces.map((storagePlace: IStoragePlace) => (
          <Typography
            key="storage-place-popover"
            sx={{ margin: (theme) => theme.spacing(1) }}
          >
            <LinkUi component={Link} to={`/storage-places/${storagePlace.id}`}>
              {storagePlace.name}
            </LinkUi>
          </Typography>
        ))}
      </Popover>,
    ];
  }
  return "Aucun lieu de stockage";
};

export default WarehouseStoragePlacesNames;
