import Typography from "@mui/material/Typography";
import LinkUi from "@mui/material/Link";
import { Link } from "react-router-dom";
// @ts-ignore
import Pluralize from "react-pluralize";
import CloseModal from "../Modals/CloseModal";
import { IProduct } from "types/product.type";
import { IStoragePlace } from "src/types/storage-place.type";

interface IStoragePlaceLinkedToProductsModalProps {
  transitionDurationEnter?: number;
  title: string;
  handleClose: () => void;
  storagePlace?: IStoragePlace;
  productsLinkeds: Array<IProduct>;
}

const StoragePlaceLinkedToProductsModal = (
  props: IStoragePlaceLinkedToProductsModalProps,
) => {
  const {
    transitionDurationEnter = 300,
    title,
    handleClose,
    storagePlace,
    productsLinkeds,
  } = props;

  return (
    <CloseModal
      key="storage-place-linked-to-product"
      open
      title={`Mise à jour du lieu de stockage "${storagePlace?.name}".`}
      handleClose={handleClose}
      transitionDurationEnter={transitionDurationEnter}
    >
      <Typography>{title}</Typography>
      <Typography>
        {`Il est lié à `}
        <Pluralize singular="produit" count={productsLinkeds.length} />
        {` :`}
      </Typography>
      <ul>
        {productsLinkeds.map((linked) => (
          <li key={linked.id}>
            <LinkUi
              to={`/products/${linked.id}`}
              component={Link}
              underline="none"
            >
              {linked.name}
            </LinkUi>
          </li>
        ))}
      </ul>
    </CloseModal>
  );
};

export default StoragePlaceLinkedToProductsModal;
