import { useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { makeStyles } from "tss-react/mui";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Autocomplete from "@mui/lab/Autocomplete";
import isUuid from "is-uuid";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import PageLoader from "../Loaders/PageLoader";
import { useAddProductToAUsePlace } from "Hooks/products.hook";
import SnackbarCloseIcon from "../Snackbar/SnackbarCloseIcon";
import { Theme } from "@mui/material";
import { IProduct } from "types/product.type";
import { IWarehouse } from "types/warehouse.type";
import { AxiosResponse } from "axios";
import { IUsePlace } from "types/use-place.type";

const useStyles = makeStyles()((theme: Theme) => ({
  paper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2, 3, 3),
  },
  submit: {
    float: "right",
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  form: {
    width: "100%",
  },
  noWarehouseOrUsePlace: {
    margin: "auto",
    textAlign: "center",
  },
}));

interface IProductAddUsePlaceProps {
  loading?: boolean;
  product: IProduct;
  warehouses: Array<IWarehouse>;
}

const ProductAddUsePlace = (props: IProductAddUsePlaceProps) => {
  const { loading = false, product, warehouses } = props;

  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  interface IInitialValues {
    warehouseId: string;
    usePlaceId: string;
    usePlaceName?: IUsePlace["name"];
  }
  const initialValues: IInitialValues = {
    warehouseId: "",
    usePlaceId: "",
  };

  const [usePlaceName, setUsePlaceName] = useState<IUsePlace["name"] | null>(
    null,
  );
  const [resetForm, setResetForm] = useState<() => void>(() => {});

  const {
    mutate: addProductToAUsePlace,
    isPending: isPendingAddProductToAUsePlace,
  } = useAddProductToAUsePlace({
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ["products-history", { productId: product.id }],
        exact: true,
      });

      queryClient.refetchQueries({
        queryKey: ["product", { productId: product.id }],
        exact: true,
      });

      resetForm();
    },
    onError: ({ response }) => {
      const { data } = response as AxiosResponse;

      const productAlreadyLinkedError =
        data &&
        data.title &&
        data.title === "PRODUCT_ALREADY_LINKED_TO_USE_PLACE";
      enqueueSnackbar(
        `${
          productAlreadyLinkedError
            ? `Le produit "${product.name}" est déjà lié au lieu d'utilisation "${usePlaceName}".`
            : `Une erreur est survenue lors de l'ajout de produit "${product.name}" au lieu d'utilisation "${usePlaceName}". Veuillez réessayer.`
        }`,
        {
          variant: productAlreadyLinkedError ? "warning" : "error",
          action: (snackbarKey) => (
            <SnackbarCloseIcon snackbarKey={snackbarKey} />
          ),
        },
      );
    },
  });

  if (!loading && warehouses.length === 0) {
    return (
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h5">
          Ajouter à un lieu d'utilisation
        </Typography>
        <div className={classes.noWarehouseOrUsePlace}>
          <div>
            Aucun {warehouses.length === 0 ? "entrepôt" : "lieu d'utilisation"}
          </div>
          <div>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() =>
                navigate(
                  warehouses.length === 0 ? "/new-warehouse" : "/new-use-place",
                )
              }
            >
              Ajouter un{" "}
              {warehouses.length === 0 ? "entrepôt" : "lieu d'utilisation"}
            </Button>
          </div>
        </div>
      </Paper>
    );
  }

  return (
    <Paper className={classes.paper}>
      {loading ? (
        <PageLoader />
      ) : (
        <>
          <Typography component="h1" variant="h5">
            Ajouter à un lieu d'utilisation
          </Typography>
          <Formik
            initialValues={initialValues}
            onSubmit={(
              { usePlaceId, usePlaceName: newUsePlaceName },
              { resetForm: newResetForm },
            ) => {
              setUsePlaceName(newUsePlaceName || null);
              setResetForm(newResetForm);
              addProductToAUsePlace({
                productId: product.id,
                usePlaceId,
              });
            }}
            validationSchema={yup.object().shape({
              warehouseId: yup
                .mixed()
                .test(
                  "wrongWarehouseId",
                  "L'entrepôt est requis.",
                  (warehouseId) =>
                    isUuid.anyNonNil(warehouseId?.toString() || ""),
                ),
              usePlaceId: yup
                .mixed()
                .test(
                  "wrongWarehouseId",
                  "Le lieu de d'utilisation est requis.",
                  (usePlaceId) =>
                    isUuid.anyNonNil(usePlaceId?.toString() || ""),
                ),
            })}
            render={({
              values,
              touched,
              errors,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit} className={classes.form} noValidate>
                <Autocomplete
                  onChange={(_, value) => {
                    setFieldValue("warehouseId", value ? value.id : "");
                    setFieldValue("usePlaceId", "");
                  }}
                  value={
                    warehouses[
                      warehouses.findIndex(
                        (warehouse) => warehouse.id === values.warehouseId,
                      )
                    ] || null
                  }
                  options={warehouses}
                  getOptionLabel={(option) => option.name}
                  disableClearable
                  noOptionsText="Aucun entrepôt"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Entrepôt"
                      fullWidth
                      className={classes.textField}
                      helperText={
                        errors.warehouseId &&
                        touched.warehouseId &&
                        errors.warehouseId
                      }
                      error={!!errors.warehouseId && touched.warehouseId}
                    />
                  )}
                />

                {values.warehouseId && (
                  <Autocomplete
                    onChange={(_, value) => {
                      setFieldValue("usePlaceId", value?.id || "");
                      setFieldValue("usePlaceName", value?.name || null);
                    }}
                    value={
                      warehouses
                        .find(
                          (warehouse) => warehouse.id === values.warehouseId,
                        )
                        ?.usePlaces?.find(
                          (usePlace: IUsePlace) =>
                            usePlace.id === values.usePlaceId,
                        ) || undefined
                    }
                    options={
                      warehouses.find(
                        (warehouse) => warehouse.id === values.warehouseId,
                      )?.usePlaces || []
                    }
                    getOptionLabel={(option) => option.name}
                    disableClearable
                    noOptionsText="Aucun lieu d'utilisation"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Lieu d'utilisation"
                        fullWidth
                        className={classes.textField}
                        helperText={
                          errors.usePlaceId &&
                          touched.usePlaceId &&
                          errors.usePlaceId
                        }
                        error={!!errors.usePlaceId && touched.usePlaceId}
                      />
                    )}
                  />
                )}

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={isPendingAddProductToAUsePlace}
                >
                  {isPendingAddProductToAUsePlace && (
                    <CircularProgress color="inherit" size={14} />
                  )}
                  {!isPendingAddProductToAUsePlace &&
                    "Lier le produit à au lieu d'utilisation"}
                </Button>
              </form>
            )}
          />
        </>
      )}
    </Paper>
  );
};

export default ProductAddUsePlace;
