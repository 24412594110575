import axios from "axios";
import config from "../config";
import { IProduct } from "types/product.type";
import { IWarehouse } from "types/warehouse.type";
import {
  ICloseStockParams,
  IStockHistory,
  IUseStockParams,
} from "types/stock.type";
import { IStoragePlace } from "types/storage-place.type";

export const addStockToAProduct = (
  productId: IProduct["id"],
  storagePlaceId: IStoragePlace["id"],
  actionDate: IStockHistory["actionDate"],
  actionQuantity: IStockHistory["actionQuantity"],
) =>
  axios.post(
    `${config.API.HOST}/stockshistory/${productId}/${storagePlaceId}`,
    {
      actionDate,
      actionQuantity,
    },
  );

export const getStocksQuantityForProduct = (productId: IProduct["id"]) =>
  axios.get(`${config.API.HOST}/stocksquantity/product/${productId}`);

export const getStockHistoryByProductAndStoragePlace = (
  productId: IProduct["id"],
  storagePlaceId: IStoragePlace["id"],
) =>
  axios.get(
    `${config.API.HOST}/stockshistory/${productId}/storage-place/${storagePlaceId}`,
  );

export const getStockHistoryByProductAndWarehouse = (
  productId: IProduct["id"],
  warehouseId: IWarehouse["id"],
) =>
  axios.get(
    `${config.API.HOST}/stockshistory/${productId}/warehouse/${warehouseId}`,
  );

export const getUseStockForProductAndStoragePlace = (
  productId: IProduct["id"],
  storagePlaceId: IStoragePlace["id"],
  params: IUseStockParams,
) =>
  axios.post(
    `${config.API.HOST}/stockshistory/${productId}/${storagePlaceId}/use`,
    params,
  );

export const closeStockForProduct = (
  productId: IProduct["id"],
  storagePlaceId: IStoragePlace["id"],
  params: ICloseStockParams,
) =>
  axios.post(
    `${config.API.HOST}/stockshistory/${productId}/${storagePlaceId}/close`,
    params,
  );
